import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Election() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const buttonContainerStyle = isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : { textAlign: 'center' };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', // One column on mobile, two columns otherwise
        textAlign: 'center',
        gap: '5px', // Space between columns
    };

    return (
        <div>
            <div className="text-content">
                <h1 style={{ textAlign: 'center' }}>2024 U.S. Elections</h1>
                <br />
            </div>
            <div>
                <div style={gridContainerStyle}>
                    <div>
                        <h3>Election Narratives</h3>
                        <Link to="/election_narratives" className="btn-link">
                            <img src="https://i.imgur.com/OR0fcB0.png" alt="Election Narratives" style={{ maxWidth: '90%', maxHeight: '70%' }} />
                        </Link>
                        <div style={{ height: "0.5rem" }}></div>
                        <h4 style={{ textAlign: 'center' }}>Monitor coverage from the USA, Russia, China, and Iran.</h4>
                    </div>
                    <div>
                        <h3>Election Facts</h3>
                        <Link to="/election_facts/" className="btn-link">
                            <img src="https://i.imgur.com/n0CfaFF.png" alt="Election Facts" style={{ maxWidth: '90%', maxHeight: '70%' }} />
                        </Link>
                        <div style={{ height: "0.5rem" }}></div>
                        <h4 style={{ textAlign: 'center' }}>Fact-check your election news, all in one place.</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}
