import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle, FaSort } from 'react-icons/fa';
import './Search.css';
import '../GlobalStyles.css';
import { Link } from 'react-router-dom';

function DataTableLWV({ data, showIndex = true }) {

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const sortedData = [...data].sort((a, b) => {
            const dateA = new Date(a.date_published);
            const dateB = new Date(b.date_published);
            return dateB - dateA; // Sort by most recent date first
        });
        setFilteredData(sortedData); // Ensure filteredData state is updated when data prop changes
    }, [data]);

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [dateSortOrder, setDateSortOrder] = useState('reverse-chronological');
    const [sourceSortOrder, setSourceSortOrder] = useState('none');

    const toggleSortOrder = (column) => {
        switch (column) {
            case 'date':
                setDateSortOrder(dateSortOrder === 'reverse-chronological' ? 'chronological' : 'reverse-chronological');
                // Reset other sort orders
                setSourceSortOrder('none');
                break;
            case 'source':
                setSourceSortOrder(sourceSortOrder === 'alphabetical' ? 'reverse-alphabetical' : 'alphabetical');
                // Reset other sort orders
                setDateSortOrder('none');
                break;
            default:
                break;
        }
    };

    const getSortedData = () => {
        return [...filteredData].sort((a, b) => {
            if (sourceSortOrder !== 'none') {
                return sourceSortOrder === 'alphabetical' ?
                    (a.platform || "").localeCompare(b.platform || "") :
                    (b.platform || "").localeCompare(a.platform || "");
            }

            if (dateSortOrder !== 'none') {
                // Converting "MM/DD/YYYY" string format to Date objects for sorting
                const dateA = new Date(a.date_published);
                const dateB = new Date(b.date_published);
                return dateSortOrder === 'chronological' ? dateA - dateB : dateB - dateA;
            }

            return 0;
        });
    };

    const renderMobileTable = () => (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>Misleading Claim</th>
                        <th>
                            <span>
                                Fact Check
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((row, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div style={{ fontWeight: 500 }}>{row.misleading_claim}</div>
                                    <br />
                                    <i>{row.platform}</i>
                                    <br />
                                    <br />
                                    <b>Date:</b> {row.date_published}
                                    <br />
                                    <br />
                                </td>
                                <td>
                                    {row.fact_check}
                                    <br /><br/>
                                    <div style={{ textAlign: 'right' }}>
                                        <Link to={row.reference} className="btn-link">
                                            <button className="btn-custom btn-charcoal-gray">Reference</button>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div >
    );

    const renderDesktopTable = () => (
        <Table>
            <thead>
                <tr>
                    <th className="column-width-small" style={{ fontWeight: 500 }}>
                        Date
                        <FaSort onClick={() => toggleSortOrder('date')} style={{ cursor: 'pointer' }} />
                    </th>
                    <th className="column-width-source" style={{ fontWeight: 500 }}>
                        Misleading Claim
                    </th>
                    <th>
                        <span style={{ fontWeight: 500 }}>
                            Fact Check
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {getSortedData().map((row, index) => {
                    return (
                        <tr key={index}>
                            <td className="column-width-small">
                                {row.date_published}
                                <br />
                            </td>
                            <td className="column-width-source">
                                <div style={{ fontWeight: 500 }}>{row.misleading_claim}</div>
                                <div style={{ height: '0.75rem' }}></div>
                                <i>{row.platform}</i>
                            </td>
                            <td>
                                {row.fact_check}
                                <br /><br />
                                <span style={{ fontWeight: 500 }}>Reference: </span>
                                <a href={row.reference} style={{ color: 'black' }}>
                                    {row.reference}
                                </a>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );

    return (
        <div>
            {isMobile ? renderMobileTable() : renderDesktopTable()}
        </div>
    );
}

export default DataTableLWV;
