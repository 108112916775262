import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const USANews = () => {
    const { user } = useUser();
    const generalFilters = {
        countries: ["United States"]
    };

    const articleFeedFilters = {
        tracker_type: ["us news"],
        united_states: true
    };

    const usaNarrativeFilters = {
        "united_states": true
    };


    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>USA News Tracker</h1>
            <Tabs defaultActiveKey="global-stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="global-stories" title="Global Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={generalFilters}
                            selectedSortType="Last Updated"
                        />
                    </div>
                </Tab>
                {/* TODO: Add in turkey exclusive cluster data here */}
                <Tab eventKey="domestic-stories" title="USA Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={usaNarrativeFilters}
                            selectedSortType="Last Updated"
                            usaCluster={true}
                            trackerType={["us news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable
                        baseRequest={articleFeedFilters}
                        showCharged={true}
                        trackerType={["us news"]}
                        countrySearch={false}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors emerging news on Turkish politics and security.
                        <br /><br />
                        <b><i>Global Narratives:</i></b> Insights on Turkey from both Turkish and foreign news sources.
                        <br /><br />
                        <b><i>China Narratives:</i></b> Insights exclusively from 55 Turkish news sources.
                        <br /><br />
                        <b><i>Article Feed:</i></b> Articles on politics and security from 55 Turkish news sources.
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default USANews;
