import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBadge = styled(Badge)`
  background-color: ${props => props.backgroundColor} !important;
  color: ${props => props.textColor} !important;
  font-size: 1rem;
  font-weight: normal;
`;

const CustomBadge = ({ backgroundColor, textColor, text }) => {
  return (
    <StyledBadge backgroundColor={backgroundColor} textColor={textColor}>
      {text}
    </StyledBadge>
  );
};

CustomBadge.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CustomBadge;
