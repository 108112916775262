import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const VEOTracker = () => {
    const { user } = useUser();
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>VEO Trackers</h1>
            <Tabs defaultActiveKey="hamas" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="hamas" title="Hamas">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"hamas": true}}
                            selectedSortType="Last Updated"
                            hamasCluster={true}
                            trackerType={["hamas news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="hezbollah" title="Hezbollah">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"hezbollah": true}}
                            selectedSortType="Last Updated"
                            hezbollahCluster={true}
                            trackerType={["hezbollah news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="al-qaeda" title="Al-Qaeda">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"al_qaeda": true}} 
                            selectedSortType="Last Updated"
                            alQaedaCluster={true}
                            trackerType={["al-qaeda news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="boko-haram" title="Boko Haram">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"boko_haram": true}} 
                            selectedSortType="Last Updated"
                            bokoHaramCluster={true}
                            trackerType={["boko haram news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="isis" title="ISIS">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"isis": true}} 
                            selectedSortType="Last Updated"
                            iranCluster={true}
                            trackerType={["isis news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors 230,000+ news outlets in real-time for mentions of Violent Extremist Organizations (VEOs).
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default VEOTracker;
