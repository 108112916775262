import React, { useContext, useState, useEffect } from "react";
import '../GlobalStyles.css';
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import { useFirestore } from "../../contexts/FirestoreContext.js";
import { Link } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';
import GridLoader from 'react-spinners/GridLoader'; // Import GridLoader
import { FaTimesCircle, FaPlusCircle } from "react-icons/fa";

const MyNews = () => {

  // Track if user is signed in
  const { user } = useUser();

  // Track user bookmarks
  const { userData, removeCustomTracker } = useFirestore();
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const filters = { "tracker_type": ["international politics"] }


  useEffect(() => {
    if (userData) {
      setIsLoadingUserData(false);
    }
  }, [userData]);


  const savedTrackers = userData ? userData.customTrackers : [];

  // const [selectedTab, setSelectedTab] = useState("bookmarks");

  const [selectedTab, setSelectedTab] = useState('bookmarks');
  const [lastTrackerRemoved, setLastTrackerRemoved] = useState(false);

  const handleTabSelect = (key) => {
    setSelectedTab(key);
  };

  // useEffect(() => {
  //   console.log("L38 selected tab is", selectedTab)
  // }, [selectedTab]);

  const getDefaultStartDate = () => {
    const now = new Date();
    const past24Hours = new Date(now.getTime() - (24 * 60 * 60 * 1000)); // Subtract 24 hours from the current time
    return past24Hours.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const getDefaultEndDate = () => {
    const now = new Date();
    const tomorrow = new Date(now.setDate(now.getDate() + 1));
    return tomorrow.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };


  useEffect(() => {
    if (lastTrackerRemoved) {
      setSelectedTab("bookmarks");
    }
    setLastTrackerRemoved(false);
  }, [lastTrackerRemoved]);



  if (!user) {
    return (
      <div className="home-container">
        <div className="text-content" style={{ textAlign: 'center', margin: 'auto', maxWidth: "75%" }}>
          <h1>Welcome to VerbaAI!</h1>
          <div>
            <p>
              Authenticated users can personalize their experience by bookmarking speeches and making their own custom trackers.
              To use this feature, please sign in to VerbaAI.
              If you don't have an account yet, don't worry—it's quick and easy to create one!
            </p>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to="/login" className="btn-link">
                <button className="btn-custom btn-charcoal-gray">Sign In / Sign Up</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>My News</h1>
      <Tabs defaultActiveKey="bookmarks" activeKey={selectedTab} onSelect={handleTabSelect} id="uncontrolled-tab-example" className="border-3">
        <Tab eventKey="bookmarks" title="Bookmarks">
          {isLoadingUserData ? (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <GridLoader />
            </div>
          ) : (
            <SearchableTable baseRequest={{
              "links": userData.savedSpeeches.length > 0
                ? userData.savedSpeeches
                : ["www.acbdef.com"]
            }} />
          )}
        </Tab>

        {/* Dynamic tabs for saved trackers */}
        {savedTrackers &&
          savedTrackers.map((tracker, index) => {
            const trackerKey = `tracker${index}`;
            return (
              <Tab
                eventKey={trackerKey}
                title={
                  <div>
                    {tracker.trackerTitle}{'  '}
                    {selectedTab === trackerKey && (
                      <FaTimesCircle
                        onClick={() => {
                          const confirmation = window.confirm(
                            `Are you sure you want to delete the tracker "${tracker.trackerTitle}"?`
                          );
                          if (confirmation) {
                            removeCustomTracker(tracker.trackerTitle);
                            if (index === savedTrackers.length - 1) {
                              setLastTrackerRemoved(true);
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                }
                key={index}
              >
                <SearchableTable
                  showCharged={true}
                  baseRequest={{ ...tracker.trackerParams, query: "", date_published: [getDefaultStartDate(), getDefaultEndDate()] }}
                  preset_query={tracker.trackerParams["query"]}
                />
              </Tab>
            );
          })}


        <Tab eventKey="addCustom" title={
          <div>
            Add Custom
            {' '}
            <FaPlusCircle /> {/* Include the FaTimesCircle icon */}
          </div>
        }>
          <SearchableTable customTrackerCreator={true} />
        </Tab>
      </Tabs>
    </div>
  );
}
export default MyNews;
