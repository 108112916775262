import React from 'react';
import './Search.css';
import '../GlobalStyles.css';

const CustomCheckbox = ({ label, checked, onChange }) => {
    return (
        <div className="custom-checkbox-container" onClick={() => onChange(!checked)}>
            <div className={`custom-checkbox ${checked ? 'checked' : ''}`}>
                {checked && <span className="checkmark">✔</span>}
            </div>
            <label className="custom-checkbox-label">{label}</label>
        </div>
    );
};

export default CustomCheckbox;
