// DonutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ title, data, labels }) => {
    // Define specific colors for fixed options
    const colorMapping = {
        "Other": 'rgb(192, 192, 192)',
        "None": 'rgb(192, 192, 192)',
        "No": 'rgb(192, 192, 192)',
        "Yes": 'rgb(242, 22, 22)',
        "Happiness": 'rgb(255, 182, 193)',
        "Sadness": 'rgb(17, 176, 179)',
        "Disgust": 'rgb(255, 190, 28)',
        "Anger": 'rgb(242, 22, 22)',
        "Fear": 'rgb(122, 203, 57)',
        "Surprise": 'rgb(46, 70, 155)',
    };

    // List of colors available for variable values
    const variableColors = [
        'rgb(192, 192, 192)',  // Only for "Other" or "None"
        'rgb(46, 70, 155)',
        'rgb(243, 103, 77)',
        'rgb(17, 176, 179)',
        'rgb(255, 190, 28)',
        'rgb(122, 203, 57)',
    ];

    // Function to get a unique color that hasn’t been used yet
    const getUniqueColor = (usedColors) => {
        const availableColors = variableColors.filter(color => !usedColors.includes(color) && color !== 'rgb(192, 192, 192)');
        const selectedColor = availableColors[Math.floor(Math.random() * availableColors.length)];
        usedColors.push(selectedColor); // Track the color as used
        return selectedColor;
    };

    // Assign background colors ensuring "Other" and "None" are always gray
    const usedColors = []; // Keep track of colors already used in this chart
    const backgroundColors = labels.map(label => {
        if (label === "Other" || label === "None") {
            return colorMapping[label]; // Always use gray for "Other" and "None"
        }
        return colorMapping[label] || getUniqueColor(usedColors); // Use mapped color or get a unique one
    });

    const chartData = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: backgroundColors,
                borderColor: backgroundColors.map(color => color.replace('rgb', 'rgba').replace(')', ', 1.0)')),
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        family: 'Afacad',
                        size: 15,
                    },
                    color: 'black',
                },
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.raw} articles`,
                },
            },
        },
        cutout: '70%', // Makes the chart hollow
    };

    return (
        <div style={{ textAlign: 'center', marginBottom: '1rem', width: '100%', height: '100%' }}>
            <h4>{title}</h4>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <Doughnut data={chartData} options={options} />
            </div>
        </div>
    );
};

export default DonutChart;
