import { useParams } from "react-router-dom";
import "../GlobalStyles.css";
import SearchableCluster from "../search/SearchableCluster.js"
import SearchableTable from "../search/SearchableTable.js";
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import { unitedStates, middleEastAndNorthAfrica, pacificRim, asia, europe, americas, subSaharanAfrica } from "../pages/GlobalPulse.js";


const allCountries = [
    ...unitedStates,
    ...middleEastAndNorthAfrica,
    ...pacificRim,
    ...asia,
    ...europe,
    ...americas,
    ...subSaharanAfrica
];

const predefinedDetails = {
    united_states: {
        title: "Breaking News: United States",
        filters: { countries: unitedStates }
    },
    mena: {
        title: "Breaking News: Middle East and North Africa",
        filters: { countries: middleEastAndNorthAfrica }
    },
    pacific_rim: {
        title: "Breaking News: Pacific Rim",
        filters: { countries: pacificRim }
    },
    asia: {
        title: "Breaking News: Asia",
        filters: { countries: asia }
    },
    europe: {
        title: "Breaking News: Europe",
        filters: { countries: europe }
    },
    americas: {
        title: "Breaking News: Americas",
        filters: { countries: americas }
    },
    sub_saharan_africa: {
        title: "Breaking News: Sub-Saharan Africa",
        filters: { countries: subSaharanAfrica }
    }
};

const getIssueDetails = (issue) => {
    const details = { ...predefinedDetails };

    allCountries.forEach((country) => {
        const countryKey = country.toLowerCase().replace(/ /g, '_');
        details[countryKey] = {
            title: `Breaking News: ${country}`,
            filters: { countries: [country] }
        };
    });

    return details[issue] || null;
};


const RegionalNews = () => {
    let { issue } = useParams();
    const issueDetails = getIssueDetails(issue);
    const { user } = useUser();

    if (!issueDetails) {
        return <h1 style={{ textAlign: "center" }}>Issue Not Found</h1>;
    }

    return (
        <div>
            <h1 style={{ textAlign: "center" }}>{issueDetails.title}</h1>
            {!user && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
            )}
            <Tabs defaultActiveKey="stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="stories" title="Breaking News">
                    <SearchableCluster
                        key={issue}
                        baseRequest={issueDetails.filters}
                        selectedSortType="Top Stories"
                    />
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable
                        showCharged={true}
                        key={issue}
                        baseRequest={issueDetails.filters}
                        trackerType={["international politics"]}
                    />
                </Tab>
            </Tabs>
        </div>

    );
};

export default RegionalNews;
