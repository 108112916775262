// Context to share user-specific data for VerbaAI
// saved speeches set in Bookmarks.js

import React, { createContext, useState, useContext, useEffect } from "react";
import { useUser } from "./UserContext";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { firestoreDB } from "../firebase";

const FirestoreContext = createContext();

export const FirestoreProvider = ({ children }) => {
  const { user } = useUser();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (user) {
      // The path in Firestore where the user's data is stored
      const docPath = doc(collection(firestoreDB, 'users'), user.uid);

      // Fetch the user's data from Firestore
      getDoc(docPath).then(docSnapshot => {
        if (docSnapshot.exists()) {
          setUserData(docSnapshot.data());
        } else {
          // If there's no data for this user yet, create it
          setDoc(docPath, { savedSpeeches: [], customTrackers: [] }, { merge: true }).then(() => {
            setUserData({ savedSpeeches: [], customTrackers: [] });
          });
        }
      });
    } else {
      // If there's no user signed in, clear the Firestore data
      setUserData(null);
    }
  }, [user]);



  const saveSpeech = async (speechLink) => {
    if (userData) {
      const docPath = doc(collection(firestoreDB, 'users'), user.uid);
      const newSavedSpeeches = [...userData.savedSpeeches, speechLink];
      await setDoc(docPath, { savedSpeeches: newSavedSpeeches }, { merge: true });
      setUserData({ ...userData, savedSpeeches: newSavedSpeeches });
    }
  };

  const removeSpeech = async (speechLink) => {
    if (userData) {
      const docPath = doc(collection(firestoreDB, 'users'), user.uid);
      const newSavedSpeeches = userData.savedSpeeches.filter(link => link !== speechLink);
      await setDoc(docPath, { savedSpeeches: newSavedSpeeches }, { merge: true });
      setUserData({ ...userData, savedSpeeches: newSavedSpeeches });
    }
  };


  const addCustomTracker = async (customTracker) => {
    if (userData) {
      const docPath = doc(collection(firestoreDB, 'users'), user.uid);
      const newCustomTrackers = [...(userData.customTrackers || []), customTracker];
      await setDoc(docPath, { customTrackers: newCustomTrackers }, { merge: true });
      setUserData({ ...userData, customTrackers: newCustomTrackers });
    }
  };


  const removeCustomTracker = async (trackerTitle, callback) => {
    if (userData) {
      const docPath = doc(collection(firestoreDB, 'users'), user.uid);

      // Use the filter function to remove the custom tracker from the customTrackers array
      const newCustomTrackers = userData.customTrackers.filter(tracker => tracker.trackerTitle !== trackerTitle);

      await setDoc(docPath, { customTrackers: newCustomTrackers }, { merge: true });
      setUserData({ ...userData, customTrackers: newCustomTrackers });
    }
  };



  const doesTrackerNameExist = (trackerName) => {
    const trimmedTrackerName = trackerName.trim(); // Trim the input tracker name

    if (!userData || !Array.isArray(userData.customTrackers)) {
      return false; // No user data or no custom trackers, so the name can't exist
    }

    // Check if any tracker has the same title as the trimmed trackerName
    return userData.customTrackers.some(tracker =>
      tracker.trackerTitle.trim() === trimmedTrackerName
    );
  };


  return (
    <FirestoreContext.Provider value={{ userData, saveSpeech, removeSpeech, addCustomTracker, removeCustomTracker, doesTrackerNameExist }}>
      {children}
    </FirestoreContext.Provider>
  );
};

export const useFirestore = () => {
  const context = useContext(FirestoreContext);
  if (!context) {
    throw new Error("useFirestore must be used within a FirestoreProvider");
  }
  return context;
};