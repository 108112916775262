import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchableTable, { getDatePublished, getTimePublished } from '../search/SearchableTable.js';
import GridLoader from 'react-spinners/GridLoader';
import '../GlobalStyles.css';
import '../search/Search.css';
import DataTableCluster from '../search/DataTableCluster.js';
import { Tab, Tabs, Badge } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { Line, Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import SideBar from '../search/SideBar.js';
import DataTable from '../search/DataTable.js';
import NarrativeHistory from './NarrativeHistory.js';
import ClusterSentiment from '../visualizations/ClusterSentiment.js';
import ClusterSimilarity from '../visualizations/ClusterSimilarity.js';
import DonutChart from '../visualizations/DonutChart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    annotationPlugin
);

export function getTimezoneName(utcDateString) {
    const date = new Date(utcDateString);
    const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1];
    return timezoneName;
}

function NarrativeAnalysis({ baseRequest, customTrackerCreator = false, countrySearch = true, countryColumn = true, languageSearch = true, languageColumn = true, sourceOptions: propSourceOptions, preset_query = "", excludePast24Hours = false, showSentiment = false,
    fringeNarrative = false, chinaNarrative = false, turkeyNarrative = false, iranNarrative = false, russiaNarrative = false, usaNarrative = false,
    hamasNarrative = false, hezbollahNarrative = false, alQaedaNarrative = false, bokoHaramNarrative = false, isisNarrative = false
}) {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [clusterData, setClusterData] = useState([]); // refers to cluster table data
    const [allArticleData, setAllArticleData] = useState([]); // set once on initial load
    const [articleData, setArticleData] = useState([]); // reflects current query
    const [noResults, setNoResults] = useState(false);
    const [articleIds, setArticleIds] = useState([]);
    const [clusterDataIsLoading, setClusterDataIsLoading] = useState(true);
    const [articleDataIsLoading, setArticleDataIsLoading] = useState(true);
    const [clusterTimestamps, setClusterTimestamps] = useState([]);
    const [articleTimestamps, setArticleTimestamps] = useState([]);
    const [articleFilters, setArticleFilters] = useState(null);
    const [articleTsneDict, setArticleTsneDict] = useState({});
    const [clusterCentroidTsne, setClusterCentroidTsne] = useState([]);

    const [initialLoadDone, setInitialLoadDone] = useState(false);

    useEffect(() => {
        if (articleFilters && !initialLoadDone) {
            fetchArticleData();
            setInitialLoadDone(true);
        }
    }, [articleFilters, initialLoadDone]);

    const fullUrl = window.location.href;
    const cluster_id = fullUrl.split("/")[4];

    const clusterFilters = {
        query: "",
        sources: [],
        countries: [],
        topics: [],
        keywords: [],
        date_published: [null, null],
        links: [],
        tracker_type: [],
        cluster: true,
        article_id: [cluster_id],
        latest: true,
        fringe: fringeNarrative,
        china: chinaNarrative,
        turkey: turkeyNarrative,
        iran: iranNarrative,
        russia: russiaNarrative,
        united_states: usaNarrative,
        hamas: hamasNarrative,
        hezbollah: hezbollahNarrative,
        boko_haram: bokoHaramNarrative,
        al_qaeda: alQaedaNarrative,
        isis: isisNarrative
    };

    useEffect(() => {
        if (articleIds.length > 0) {
            setArticleFilters({
                query: "",
                sources: [],
                countries: [],
                topics: [],
                date_published: [],
                links: [],
                tracker_type: [],
                article_id: articleIds,
                latest: true,
                fringe: fringeNarrative,
                china: chinaNarrative,
                turkey: turkeyNarrative,
                iran: iranNarrative,
                russia: russiaNarrative,
                united_states: usaNarrative,
                hamas: hamasNarrative,
                hezbollah: hezbollahNarrative,
                boko_haram: bokoHaramNarrative,
                al_qaeda: alQaedaNarrative,
                isis: isisNarrative
            });
        }
    }, [articleIds]);

    useEffect(() => {
        fetchClusterData();
    }, []);

    useEffect(() => {
        setClusterDataIsLoading(clusterData.length === 0);
    }, [clusterData]);

    useEffect(() => {
        setArticleDataIsLoading(articleData.length === 0);
    }, [articleData]);

    const convertTsneFormat = (articleTsneArray) => {
        const articleTsneObject = {};
        articleTsneArray.forEach(article => {
            articleTsneObject[article.id] = [article.x, article.y];
        });
        return articleTsneObject;
    };

    const fetchClusterData = async () => {
        // console.log("clusterFilters are ", clusterFilters);
        const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', clusterFilters);
        console.log("response is ", response);

        setArticleIds(response['data'][0]['source']['article_ids']);
        let records = [];
        let articleTsneArray = [];
        let cluster_timestamps = [];

        response['data'].forEach(item => {
            const {
                cluster_size,
                article_ids,
                cluster_title,
                cluster_summary,
                creation_timestamp,
                update_timestamp,
                persons,
                topics,
                countries,
                image_url,
                article_embeddings_reduced,
                summary_history,
                title_history,
                sub_clusters
            } = item['source'];

            const uniqueId = item['id'];
            cluster_timestamps = summary_history.map(summary => summary.timestamp.replace(" ", "T") + "+00:00");

            records.push({
                "cluster_id": uniqueId,
                "cluster_size": cluster_size,
                "article_ids": article_ids,
                "current_title": cluster_title,
                "current_summary": cluster_summary,
                "creation_timestamp_utc": creation_timestamp.replace(" ", "T") + "+00:00",
                "update_timestamp_utc": update_timestamp.replace(" ", "T") + "+00:00",
                "persons": persons,
                "topics": topics,
                "countries": countries,
                "image_link": image_url,
                "article_embeddings_reduced": article_embeddings_reduced,
                "summary_history": summary_history,
                "title_history": title_history,
                "sub_clusters": sub_clusters
            });

            if (article_embeddings_reduced && Array.isArray(article_embeddings_reduced)) {
                articleTsneArray = articleTsneArray.concat(article_embeddings_reduced);
            }
        });
        console.log("l192", records);

        setClusterData(records);
        setArticleTsneDict(convertTsneFormat(articleTsneArray));
        setClusterTimestamps(cluster_timestamps)
    };

    const fetchArticleData = async () => {
        try {
            const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', articleFilters);

            let records = [];
            let articleTimestamps = [];

            // console.log("response is", response);

            response['data'].forEach(item => {
                const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary, sentiment_compound, charged, basic_emotion } = item['source'];
                const uniqueId = item['id'];
                let date = new Date(item['source']['date_published']);

                records.push({
                    "article_id": uniqueId,
                    "date_time_published_utc": item['source']['date_published'].replace(" ", "T") + "+00:00",
                    "date_published": getDatePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                    "time_published": getTimePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                    "timezone_name": new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1],
                    "link": item['source']['link'],
                    "word_count": word_count,
                    "title": title,
                    "source_url": source_url,
                    "country": country,
                    "language": language,
                    "source": website_name,
                    "country_rank": country_rank,
                    "site_rank": site_rank,
                    "topics": topics,
                    "locations": locations,
                    "organizations": organizations,
                    "persons": persons,
                    "summary": summary,
                    "image_link": item['source']['image'] || null,
                    "sentiment_compound": sentiment_compound || null,
                    "charged": charged || null,
                    "basic_emotion": basic_emotion || null,
                });
                articleTimestamps.push(item['source']['date_published'].replace(" ", "T") + "+00:00");
            });

            // console.log("records are", records)

            if (!initialLoadDone) {
                setAllArticleData(records);
            }

            setArticleData(records);
            setNoResults(records.length === 0);
            setArticleTimestamps(articleTimestamps);
        }
        catch (error) {
            console.error('Error fetching latest article data:', error);
        }
    };

    const mapSentiment = (score) => {
        if (score <= -0.6) return { text: "Strongly Negative", color: '#DE0A26', textColor: "#FFFFFF" };
        if (score > -0.6 && score <= -0.2) return { text: "Moderately Negative", color: '#F7B4BB', textColor: "#222222" };
        if (score > -0.2 && score <= 0.2) return { text: "Neutral", color: '#CCCCCC', textColor: "#222222" };
        if (score > 0.2 && score <= 0.6) return { text: "Moderately Positive", color: '#B5FE89', textColor: "#222222" };
        if (score > 0.6) return { text: "Strongly Positive", color: '#44B200', textColor: "#FFFFFF" };
    };

    const getTopSources = (articleData) => {
        const sourceInfo = articleData.reduce((accumulator, article) => {
            const { source, country } = article;
            if (source) {
                if (accumulator[source]) {
                    accumulator[source].count += 1;
                } else {
                    accumulator[source] = { country, count: 1 };
                }
            }
            return accumulator;
        }, {});

        const sortedSourceArray = Object.entries(sourceInfo)
            .map(([key, value]) => ({
                source: key,
                country: value.country,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        const topSources = sortedSourceArray.reduce((acc, item) => {
            acc[item.source] = {
                country: item.country,
                count: item.count
            };
            return acc;
        }, {});

        return topSources;
    };


    const getAllSources = (articleData) => {
        const sourceInfo = articleData.reduce((accumulator, article) => {
            const { source, country } = article;
            if (source) {
                if (accumulator[source]) {
                    accumulator[source].count += 1;
                } else {
                    accumulator[source] = { country, count: 1 };
                }
            }
            return accumulator;
        }, {});

        // Sort and slice to get the top 8 sources
        const sortedSourceArray = Object.entries(sourceInfo)
            .map(([key, value]) => ({
                source: key,
                country: value.country,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const topFiveSources = sortedSourceArray.slice(0, 5);
        const otherSources = sortedSourceArray.slice(5);

        // Calculate the total count for "Other"
        const otherCount = otherSources.reduce((sum, source) => sum + source.count, 0);

        // Format the result with "Other" as the 6th entry
        const topSources = topFiveSources.reduce((acc, item) => {
            acc[item.source] = {
                country: item.country,
                count: item.count
            };
            return acc;
        }, {});

        if (otherCount > 0) {
            topSources["Other"] = {
                country: null,
                count: otherCount
            };
        }
        // console.log("l333", topSources)
        return topSources;
    };


    const getTopCountries = (articleData) => {
        const countryInfo = articleData.reduce((accumulator, article) => {
            const { country } = article;
            if (country) {
                if (accumulator[country]) {
                    accumulator[country] += 1;
                } else {
                    accumulator[country] = 1;
                }
            }
            return accumulator;
        }, {});

        const sortedCountryArray = Object.entries(countryInfo)
            .map(([key, value]) => ({
                country: key,
                count: value
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        const topCountries = sortedCountryArray.reduce((acc, item) => {
            acc[item.country] = item.count;
            return acc;
        }, {});

        return topCountries;
    };

    const getAllCountries = (articleData) => {
        const countryInfo = articleData.reduce((accumulator, article) => {
            const { country } = article;
            if (country) {
                if (accumulator[country]) {
                    accumulator[country].count += 1;
                } else {
                    accumulator[country] = { count: 1 };
                }
            }
            return accumulator;
        }, {});

        const sortedCountryArray = Object.entries(countryInfo)
            .map(([key, value]) => ({
                country: key,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const topCountries = sortedCountryArray.slice(0, 5);
        const otherCountries = sortedCountryArray.slice(5);

        const otherCount = otherCountries.reduce((sum, country) => sum + country.count, 0);

        const finalCountries = topCountries.reduce((acc, item) => {
            acc[item.country] = { count: item.count };
            return acc;
        }, {});

        if (otherCount > 0) {
            finalCountries["Other"] = { count: otherCount };
        }

        return finalCountries;
    };



    const getTopLanguages = (articleData) => {
        const languageCount = articleData.reduce((accumulator, article) => {
            const { language } = article;
            if (language) {
                if (accumulator[language]) {
                    accumulator[language] += 1;
                } else {
                    accumulator[language] = 1;
                }
            }
            return accumulator;
        }, {});

        const sortedLanguages = Object.entries(languageCount)
            .map(([key, value]) => ({
                language: key,
                count: value
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        const topLanguages = sortedLanguages.reduce((acc, item) => {
            acc[item.language] = item.count;
            return acc;
        }, {});

        return topLanguages;
    };

    const getAllLanguages = (articleData) => {
        const languageInfo = articleData.reduce((accumulator, article) => {
            const { language } = article;
            if (language) {
                if (accumulator[language]) {
                    accumulator[language].count += 1;
                } else {
                    accumulator[language] = { count: 1 };
                }
            }
            return accumulator;
        }, {});

        const sortedLanguageArray = Object.entries(languageInfo)
            .map(([key, value]) => ({
                language: key,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const topLanguages = sortedLanguageArray.slice(0, 5);
        const otherLanguages = sortedLanguageArray.slice(5);

        const otherCount = otherLanguages.reduce((sum, language) => sum + language.count, 0);

        const finalLanguages = topLanguages.reduce((acc, item) => {
            acc[item.language] = { count: item.count };
            return acc;
        }, {});

        if (otherCount > 0) {
            finalLanguages["Other"] = { count: otherCount };
        }

        return finalLanguages;
    };

    const getAllBasicEmotions = (articleData) => {
        const emotionInfo = articleData.reduce((accumulator, article) => {
            const { basic_emotion } = article;
            if (basic_emotion) {
                if (accumulator[basic_emotion]) {
                    accumulator[basic_emotion].count += 1;
                } else {
                    accumulator[basic_emotion] = { count: 1 };
                }
            }
            return accumulator;
        }, {});

        const sortedEmotionArray = Object.entries(emotionInfo)
            .map(([emotion, value]) => ({
                emotion,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const finalEmotions = sortedEmotionArray.slice(0, 7).reduce((acc, item) => {
            acc[item.emotion] = { count: item.count };
            return acc;
        }, {});

        return finalEmotions;
    };

    const getAllCharged = (articleData) => {
        const chargedInfo = articleData.reduce((accumulator, article) => {
            let { charged } = article;
    
            // Ensure charged is either "Yes" or "No"
            if (charged !== "Yes" && charged !== "No") {
                charged = "No";
            }
    
            if (charged) {
                if (accumulator[charged]) {
                    accumulator[charged].count += 1;
                } else {
                    accumulator[charged] = { count: 1 };
                }
            }
            return accumulator;
        }, {});
    
        const sortedChargedArray = Object.entries(chargedInfo)
            .map(([charge, value]) => ({
                charge,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);
    
        const finalCharged = sortedChargedArray.slice(0, 7).reduce((acc, item) => {
            acc[item.charge] = { count: item.count };
            return acc;
        }, {});
    
        return finalCharged;
    };    



    const getTopLocations = (articleData) => {
        const locationCount = articleData.reduce((accumulator, article) => {
            const { locations } = article;
            if (locations && Array.isArray(locations)) {
                const uniqueLocations = new Set(locations);
                uniqueLocations.forEach(location => {
                    if (accumulator[location]) {
                        accumulator[location] += 1;
                    } else {
                        accumulator[location] = 1;
                    }
                });
            }
            return accumulator;
        }, {});

        const sortedLocations = Object.entries(locationCount)
            .map(([location, count]) => ({
                location,
                count
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        const topLocations = sortedLocations.reduce((acc, item) => {
            acc[item.location] = item.count;
            return acc;
        }, {});

        return topLocations;
    };

    const getAllLocations = (articleData) => {
        const locationCount = articleData.reduce((accumulator, article) => {
            const { locations } = article;
            if (locations && Array.isArray(locations)) {
                const uniqueLocations = new Set(locations);
                uniqueLocations.forEach(location => {
                    if (accumulator[location]) {
                        accumulator[location].count += 1;
                    } else {
                        accumulator[location] = { count: 1 };
                    }
                });
            }
            return accumulator;
        }, {});

        const sortedLocations = Object.entries(locationCount)
            .map(([location, value]) => ({
                location,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const topLocations = sortedLocations.slice(0, 5);
        const otherLocations = sortedLocations.slice(5);

        const otherCount = otherLocations.reduce((sum, location) => sum + location.count, 0);

        const finalLocations = topLocations.reduce((acc, item) => {
            acc[item.location] = { count: item.count };
            return acc;
        }, {});

        if (otherCount > 0) {
            finalLocations["Other"] = { count: otherCount };
        }

        return finalLocations;
    };


    const getTopOrganizations = (articleData) => {
        const organizationCount = articleData.reduce((accumulator, article) => {
            const { organizations } = article;
            if (organizations && organizations.length > 0) {
                organizations.forEach(org => {
                    if (accumulator[org]) {
                        accumulator[org] += 1;
                    } else {
                        accumulator[org] = 1;
                    }
                });
            }
            return accumulator;
        }, {});

        const sortedOrganizationsArray = Object.entries(organizationCount)
            .map(([key, value]) => ({
                organization: key,
                count: value
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        const topOrganizations = sortedOrganizationsArray.reduce((acc, item) => {
            acc[item.organization] = item.count;
            return acc;
        }, {});

        return topOrganizations;
    };

    const getAllOrganizations = (articleData) => {
        const organizationCount = articleData.reduce((accumulator, article) => {
            const { organizations } = article;
            if (organizations && organizations.length > 0) {
                organizations.forEach(org => {
                    if (accumulator[org]) {
                        accumulator[org].count += 1;
                    } else {
                        accumulator[org] = { count: 1 };
                    }
                });
            }
            return accumulator;
        }, {});

        const sortedOrganizationsArray = Object.entries(organizationCount)
            .map(([organization, value]) => ({
                organization,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const topOrganizations = sortedOrganizationsArray.slice(0, 5);
        const otherOrganizations = sortedOrganizationsArray.slice(5);

        const otherCount = otherOrganizations.reduce((sum, org) => sum + org.count, 0);

        const finalOrganizations = topOrganizations.reduce((acc, item) => {
            acc[item.organization] = { count: item.count };
            return acc;
        }, {});

        if (otherCount > 0) {
            finalOrganizations["Other"] = { count: otherCount };
        }

        return finalOrganizations;
    };


    const getTopPersons = (articleData) => {
        const personCount = articleData.reduce((accumulator, article) => {
            const { persons } = article;
            if (persons && Array.isArray(persons)) {
                persons.forEach(person => {
                    if (accumulator[person]) {
                        accumulator[person] += 1;
                    } else {
                        accumulator[person] = 1;
                    }
                });
            }
            return accumulator;
        }, {});

        const sortedPersonsArray = Object.entries(personCount)
            .map(([name, count]) => ({
                name,
                count
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        const topPersons = sortedPersonsArray.reduce((acc, item) => {
            acc[item.name] = item.count;
            return acc;
        }, {});

        return topPersons;
    };

    const getAllPersons = (articleData) => {
        const personCount = articleData.reduce((accumulator, article) => {
            const { persons } = article;
            if (persons && Array.isArray(persons)) {
                persons.forEach(person => {
                    if (accumulator[person]) {
                        accumulator[person].count += 1;
                    } else {
                        accumulator[person] = { count: 1 };
                    }
                });
            }
            return accumulator;
        }, {});

        const sortedPersonsArray = Object.entries(personCount)
            .map(([name, value]) => ({
                name,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count);

        const topPersons = sortedPersonsArray.slice(0, 5);
        const otherPersons = sortedPersonsArray.slice(5);

        const otherCount = otherPersons.reduce((sum, person) => sum + person.count, 0);

        const finalPersons = topPersons.reduce((acc, item) => {
            acc[item.name] = { count: item.count };
            return acc;
        }, {});

        if (otherCount > 0) {
            finalPersons["Other"] = { count: otherCount };
        }

        return finalPersons;
    };


    const calculateAverageSentiment = (articleData) => {
        if (articleData.length === 0) {
            return 0;
        }

        const totalSentiment = articleData.reduce((sum, article) => {
            return sum + article.sentiment_compound;
        }, 0);

        const averageSentiment = totalSentiment / articleData.length;
        return Math.round(averageSentiment * 100) / 100;
    };

    const updateFilters = (newFilters) => {
        setArticleFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
    };

    const [sourceOptions, setSourceOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [sentimentOptions, setSentimentOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);

    useEffect(() => {
        if (articleData.length > 0) {
            const sources = [...new Set(allArticleData.map(article => `${article.source} - ${article.source_url}`))]
                .sort()
                .map(source => ({ label: source, value: source }));
            setSourceOptions(sources);

            const languages = [...new Set(allArticleData.map(article => article.language))]
                .sort()
                .map(language => ({ label: language, value: language }));
            setLanguageOptions(languages);

            const countries = [...new Set(allArticleData.map(article => article.country))]
                .sort()
                .map(country => ({ label: country, value: country }));
            setCountryOptions(countries);

            const sentiments = [...new Set(allArticleData.map(article => mapSentiment(article.sentiment_compound).text))]
                .sort()
                .map(sentiment => ({ label: sentiment, value: sentiment }));
            setSentimentOptions(sentiments);

            const topics = [...new Set(allArticleData.flatMap(article => article.topics))]
                .sort()
                .map(topic => ({ label: topic, value: topic }));
            setTopicOptions(topics);
        }
    }, [allArticleData]);

    const [selectedSources, setselectedSources] = useState(null);
    const handleSourceChange = selectedOption => {
        setselectedSources(selectedOption);
        updateFilters({ sources: selectedOption.map(option => option.value) });
    };

    const [selectedLanguages, setSelectedLanguages] = useState(null);
    const handleLanguageChange = selectedOption => {
        setSelectedLanguages(selectedOption);
        updateFilters({ languages: selectedOption.map(option => option.value) });
    };

    const [selectedCountries, setselectedCountries] = useState(null);
    const handleCountryChange = selectedOption => {
        setselectedCountries(selectedOption);
        updateFilters({ countries: selectedOption.map(option => option.value) });
    };

    const [selectedTopics, setselectedTopics] = useState(null);
    const handleTopicChange = selectedOption => {
        setselectedTopics(selectedOption);
        updateFilters({ topics: selectedOption.map(option => option.value) });
    };

    const handleTimeRangeChange = selectedOption => {
        setSelectedTimeRange(selectedOption);
        const now = new Date();
        let startDate = now;
        let endDate = new Date(now.setDate(now.getDate() + 1));

        switch (selectedOption.value) {
            case "Past 48 Hours":
                startDate = new Date(new Date().setDate(now.getDate() - 1));
                break;
            case "Past Week":
                startDate = new Date(new Date().setDate(now.getDate() - 7));
                break;
            case "Past Month":
                startDate = new Date(new Date().setMonth(now.getMonth() - 1));
                break;
            case "Past 4 Months":
                startDate = new Date('2024-01-01T00:00:00');
                break;
            case "Custom Range":
                return;
            default:
                break;
        }

        const formattedStartDate = formatDateToString(startDate);
        const formattedEndDate = formatDateToString(endDate);

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        updateFiltersWithDates(formattedStartDate, formattedEndDate);
    };

    const [dateError, setDateError] = useState('');

    const validateDateFormat = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        return regex.test(date);
    };

    const validateDateRange = (start, end) => {
        if (!start) {
            return true;
        }
        const defaultEndDate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const endDate = end ? new Date(end) : new Date(defaultEndDate);
        const startDate = new Date(start);

        return startDate < endDate;
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);

        if (!newStartDate || validateDateFormat(newStartDate)) {
            updateFilters({ date_published: [newStartDate, endDate] });
        }

        if (newStartDate === '') {
            setDateError('');
            return;
        }

        if (!validateDateFormat(newStartDate)) {
            setDateError('Please use MM/DD/YYYY.');
        }
        else if (!validateDateRange(newStartDate, endDate)) {
            setDateError('Start date must be before end date.');
        }
        else {
            setDateError('');
            updateFilters({ date_published: [newStartDate, endDate] });
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);

        if (!newEndDate || validateDateFormat(newEndDate)) {
            updateFilters({ date_published: [startDate, newEndDate] });
        }

        if (newEndDate === '') {
            setDateError('');
            return;
        }

        if (!validateDateFormat(newEndDate)) {
            setDateError('Please use MM/DD/YYYY.');
        } else if (!validateDateRange(startDate, newEndDate)) {
            setDateError('Start date must be before end date.');
        } else {
            setDateError('');
            updateFilters({ date_published: [startDate, newEndDate] });
        }
    };

    const updateFiltersWithDates = (startDate, endDate) => {
        const newFilters = { ...articleFilters, date_published: [startDate, endDate] };
        setArticleFilters(newFilters);
        fetchArticleData(newFilters);
    };

    const formatDateToString = (date) => {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const timeRangeOptions = excludePast24Hours ? [
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
        { label: "Custom Range", value: "Custom Range" },
    ] : [
        { label: "Past 48 Hours", value: "Past 48 Hours" },
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
        { label: "Custom Range", value: "Custom Range" },
    ];
    const [selectedTimeRange, setSelectedTimeRange] = useState(timeRangeOptions.find(option => option.value === "Past 48 Hours"));

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [selectedSentiments, setSelectedSentiments] = useState(null);
    const handleSentimentChange = selectedOption => {
        setSelectedSentiments(selectedOption);
        const selectedRanges = selectedOption.map(option => mapSentimentToRange(option.value));
        const minRange = Math.min(...selectedRanges.map(range => range[0]));
        const maxRange = Math.max(...selectedRanges.map(range => range[1]));
        updateFilters({ sentiment_compound: [minRange, maxRange] });
    };
    const mapSentimentToRange = (text) => {
        switch (text) {
            case "Strongly Negative":
                return [-1.0, -0.6];
            case "Moderately Negative":
                return [-0.6, -0.2];
            case "Neutral":
                return [-0.2, 0.2];
            case "Moderately Positive":
                return [0.2, 0.6];
            case "Strongly Positive":
                return [0.6, 1.0];
            default:
                return [null, null];
        }
    };

    const [selectedSemanticSearch, setSemanticSearch] = useState('');
    const handleSemanticSearchChange = (e) => {
        const searchValue = e.target.value;
        setSemanticSearch(searchValue);
        updateFilters({ query: searchValue });
    };

    const handleSearchClick = async () => {
        try {
            setArticleDataIsLoading(true);
            fetchArticleData();
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const handleRefreshClick = async () => {
        handleSearchClick();
    };

    const sidebar = (
        <div className="sidebar">
            <SideBar
                sourceOptions={sourceOptions}
                selectedSources={selectedSources}
                onSourceChange={handleSourceChange}

                languageOptions={languageOptions}
                selectedLanguages={selectedLanguages}
                onLanguageChange={handleLanguageChange}
                languageSearch={languageSearch}

                countryOptions={countryOptions}
                selectedCountries={selectedCountries}
                onCountryChange={handleCountryChange}
                countrySearch={countrySearch}

                topicOptions={topicOptions}
                selectedTopics={selectedTopics}
                onTopicChange={handleTopicChange}

                sentimentOptions={sentimentOptions}
                selectedSentiments={selectedSentiments}
                onSentimentChange={handleSentimentChange}
                // showSentiment={true}

                timeRangeOptions={timeRangeOptions}
                selectedTimeRange={selectedTimeRange}
                onTimeRangeChange={handleTimeRangeChange}

                selectedSemanticSearch={selectedSemanticSearch}
                onSemanticSearchChange={handleSemanticSearchChange}

                startDate={startDate}
                onStartDateChange={handleStartDateChange}
                endDate={endDate}
                onEndDateChange={handleEndDateChange}
                dateError={dateError}

                handleSearchClick={handleSearchClick}
                handleRefreshClick={handleRefreshClick}

                trackerNameError={false}
                customTrackerCreator={false}
            />
        </div>
    );

    let clusterDataContent;

    if (clusterDataIsLoading) {
        clusterDataContent = (
            <div style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else {
        clusterDataContent = (
            <div>
                <NarrativeHistory data={clusterData} />
            </div>
        );
    }

    let dataTableContent;

    if (!noResults && articleDataIsLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else if (clusterDataIsLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else if (noResults && articleDataIsLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br />
                <h2>No results found.</h2>
            </div>
        );
    }
    else {
        dataTableContent = (
            <>
                <div className="data-table">
                    <DataTable
                        data={articleData}
                        semanticSearch={selectedSemanticSearch}
                        countryColumn={countryColumn}
                        languageColumn={languageColumn}
                        // showSentiment={true}
                    />
                </div>
            </>
        );
    }

    const renderInsightsTabContent = () => {
        if (!clusterData || clusterData.length === 0 || articleDataIsLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <br />
                    <h3>Analyzing Articles...</h3>
                    <br />
                    <GridLoader />
                </div>
            );
        } else {
            const Heading = isMobile ? 'h5' : 'h4';
            return (
                <>
                    <div style={{ height: "0.5rem" }}></div>
                    <i>
                        Updated: {getDatePublished(clusterData[0].update_timestamp_utc)},
                        {" "}{getTimePublished(clusterData[0].update_timestamp_utc)}
                        {" "}({getTimezoneName(clusterData[0].update_timestamp_utc)})
                    </i>
                    <br />
                    <br />
                    <Heading>Overview</Heading>
                    {clusterData[0].current_summary.split("\n").map((item, index) => (
                        <div key={index}>{item.replace(/^- /, "• ")}</div>
                    ))}
                    <br />
                    {!isMobile && (
                        <Heading>Started Tracking: {getDatePublished(clusterData[0].creation_timestamp_utc)}, {getTimePublished(clusterData[0].creation_timestamp_utc)} ({getTimezoneName(clusterData[0].update_timestamp_utc)})</Heading>
                    )}
                    {articleDataIsLoading ? (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {noResults ? (
                                <h3>No results found.</h3>
                            ) : (
                                <>
                                    <h3>Analyzing Articles...</h3>
                                    <br />
                                    <GridLoader />
                                </>
                            )}
                        </div>
                    ) : (
                        <>
                            {isMobile ? (
                                <>
                                    <Heading>Started Tracking</Heading>
                                    {getDatePublished(clusterData[0].creation_timestamp_utc)}, {getTimePublished(clusterData[0].creation_timestamp_utc)} ({getTimezoneName(clusterData[0].update_timestamp_utc)})
                                    <br /><br />
                                    <Heading>Relevant Articles</Heading>
                                    {articleData.length}
                                    <br /><br />
                                    <div style={{ height: '0.75rem' }}></div>
                                    <hr />
                                    <div style={{ height: '0.75rem' }}></div>
                                    <DonutChart
                                        title="Article Sources"
                                        labels={Object.keys(getAllSources(articleData))}
                                        data={Object.values(getAllSources(articleData)).map(item => item.count)}
                                    />
                                    <DonutChart
                                        title="Source Countries"
                                        labels={Object.keys(getAllCountries(articleData))}
                                        data={Object.values(getAllCountries(articleData)).map(item => item.count)}
                                    />
                                    <DonutChart
                                        title="Article Languages"
                                        labels={Object.keys(getAllLanguages(articleData))}
                                        data={Object.values(getAllLanguages(articleData)).map(item => item.count)}
                                    />
                                    <DonutChart
                                        title="Mentioned Locations"
                                        labels={Object.keys(getAllLocations(articleData))}
                                        data={Object.values(getAllLocations(articleData)).map(item => item.count)}
                                    />
                                    <DonutChart
                                        title="Mentioned Organizations"
                                        labels={Object.keys(getAllOrganizations(articleData))}
                                        data={Object.values(getAllOrganizations(articleData)).map(item => item.count)}
                                    />
                                    <DonutChart
                                        title="Mentioned Persons"
                                        labels={Object.keys(getAllPersons(articleData))}
                                        data={Object.values(getAllPersons(articleData)).map(item => item.count)}
                                    />
                                </>
                            ) : (
                                <>
                                    <br />
                                    <Heading>Relevant Articles: {articleData.length}</Heading>
                                    <br />
                                    <div className="plans-container">
                                        <div className="key-insights mb-2">
                                            <DonutChart
                                                title="Article Sources"
                                                labels={Object.keys(getAllSources(articleData))}
                                                data={Object.values(getAllSources(articleData)).map(item => item.count)}
                                            />
                                        </div>
                                        <div className="key-insights mb-2">
                                            <DonutChart
                                                title="Source Countries"
                                                labels={Object.keys(getAllCountries(articleData))}
                                                data={Object.values(getAllCountries(articleData)).map(item => item.count)}
                                            />
                                        </div>
                                        <div className="key-insights mb-2">
                                            <DonutChart
                                                title="Article Languages"
                                                labels={Object.keys(getAllLanguages(articleData))}
                                                data={Object.values(getAllLanguages(articleData)).map(item => item.count)}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="plans-container">
                                        <div className="key-insights mb-2">
                                            <DonutChart
                                                title="Mentioned Locations"
                                                labels={Object.keys(getAllLocations(articleData))}
                                                data={Object.values(getAllLocations(articleData)).map(item => item.count)}
                                            />
                                        </div>
                                        <div className="key-insights mb-2">
                                            <DonutChart
                                                title="Mentioned Organizations"
                                                labels={Object.keys(getAllOrganizations(articleData))}
                                                data={Object.values(getAllOrganizations(articleData)).map(item => item.count)}
                                            />
                                        </div>
                                        <div className="key-insights mb-2">
                                            <DonutChart
                                                title="Mentioned Persons"
                                                labels={Object.keys(getAllPersons(articleData))}
                                                data={Object.values(getAllPersons(articleData)).map(item => item.count)}
                                            />
                                        </div>
                                    </div>
                                </>

                            )}
                        </>
                    )}
                </>
            );
        }
    };

    const renderInsightList = (title, data, includeCountry = false) => {
        const Heading = isMobile ? 'h5' : 'h4';
        return (
            <>
                <Heading>{title}</Heading>
                <div style={{ height: '0.5rem' }}></div>
                <ul>
                    {Object.entries(data).map(([key, value], index) => (
                        <li key={index}>
                            <span style={{ fontWeight: 500 }}>
                                {key}
                                {includeCountry && value.country ? ` (${value.country})` : ''}
                            </span>: {value.count || value} {value.count === 1 || value === 1 ? 'Article' : 'Articles'}
                        </li>
                    ))}
                </ul>
            </>
        );
    };

    const renderVisualizeTabContent = () => {
        if (isMobile) {
            return (
                <h3 style={{ textAlign: "center" }}>To see visualizations, please visit on a tablet, laptop, or desktop.</h3>
            );
        } else if (articleDataIsLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {noResults ? (
                        <h3>No results found.</h3>
                    ) : (
                        <>
                            <br />
                            <GridLoader />
                        </>
                    )}
                </div>
            );
        } else {
            return (
                <div className="chart-container">
                    {!clusterDataIsLoading && articleData.length > 0 && (
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <h3>Article Similarity</h3>
                                <i>Articles are grouped by subnarrative.</i>
                            </div>
                            <ClusterSimilarity
                                articleTsne={articleTsneDict}
                                articleData={articleData}
                                clusterData={clusterData}
                            />
                            <br /><br />
                            <div style={{ textAlign: 'center' }}>
                                <h3>Sentiment Analysis</h3>
                                <i>Based on word choice, grammar, punctuation, and subject matter context.</i>
                            </div>
                            <br />
                            <div className="plans-container">
                                <div className="key-insights mb-2">
                                    <DonutChart
                                        title="Emotional Tone"
                                        labels={Object.keys(getAllBasicEmotions(articleData))}
                                        data={Object.values(getAllBasicEmotions(articleData)).map(item => item.count)}
                                    />
                                </div>
                                <div className="key-insights mb-2">
                                    <DonutChart
                                        title="Uses Charged Language"
                                        labels={Object.keys(getAllCharged(articleData))}
                                        data={Object.values(getAllCharged(articleData)).map(item => item.count)}
                                    />
                                </div>
                            </div>
                            {/* <br /><br />
                            <div style={{ textAlign: 'center' }}>
                                <h3>Lexical Sentiment Analysis</h3>
                                <i>Lexically positive articles are higher on y-axis.</i>
                            </div>
                            <ClusterSentiment
                                articleData={articleData}
                                clusterTimestamps={clusterTimestamps}
                                mapSentiment={mapSentiment}
                            /> */}
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div>
            <div className="text-content" style={{ textAlign: 'center' }}>
                {!isMobile && <h1>Narrative Analysis</h1>}
                {clusterData.length > 0 && clusterData[0].current_title && (
                    isMobile ? (
                        <h4>{clusterData[0].current_title}</h4>
                    ) : (
                        <h3>{clusterData[0].current_title}</h3>
                    )
                )}
            </div>
            <div className="searchable-container">
                <div className='sidebar'>
                    {sidebar}
                </div>
                <div className='data-table'>
                    <Tabs defaultActiveKey="insights" id="uncontrolled-tab-example" className="border-3">
                        <Tab eventKey="insights" title={isMobile ? "Insights" : "Key Insights"}>
                            {renderInsightsTabContent()}
                        </Tab>
                        <Tab eventKey="history" title={isMobile ? "History" : "Narrative History"}>
                            {clusterDataContent}
                        </Tab>
                        {!isMobile && (
                            <Tab eventKey="visualize" title="Visualize">
                                <br />
                                {renderVisualizeTabContent()}
                            </Tab>
                        )}
                        <Tab eventKey="articles" title={isMobile ? "Articles" : "Relevant Articles"}>
                            {dataTableContent}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default NarrativeAnalysis;
