import React, { useState, useEffect } from 'react';
import './Search.css';
import '../GlobalStyles.css';
import SideBar from './SideBar.js';
import DataTable from './DataTable.js';
import GridLoader from 'react-spinners/GridLoader';
import axios from 'axios';
import { useFirestore } from "../../contexts/FirestoreContext";
import { Tab, Tabs, Badge } from 'react-bootstrap';


// Need to figure out the appropriate place for "data" to go
// I'm thinking we should set "data" to the 20 most recent speeches in ES by default
// Data can be defined on separate pages by fetching an ES query and loading it in

// Add more notes for Jeremy here

export function getDatePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    return `${utcDate.getMonth() + 1}`.padStart(2, '0') +
        `/${utcDate.getDate()}`.padStart(2, '0') +
        `/${utcDate.getFullYear()}`;
}

export function getTimePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    let hours = utcDate.getHours();
    let minutes = utcDate.getMinutes();

    // Round minutes to the nearest 10, and adjust hours if necessary
    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }

    // If hours reach 24, set it back to 23 and minutes to 50
    if (hours === 24) {
        hours = 23;
        minutes = 50;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}



// Data should be defined by some top-level parameters
// pass in query parameters -- in this case, the query parameter would be tracker_type: "international politics"
function SearchableTable({ baseRequest, customTrackerCreator = false, countrySearch = true,
    countryColumn = true, languageSearch = true, languageColumn = true, sourceOptions: propSourceOptions,
    orgOptions: propOrgOptions, preset_query = "", excludePast24Hours = false, showSentiment = false, showCluster = true,
    showSideBar = true, shareArticle = false, congressTracker = false, fringeTracker = false,
    showCharged = false, showOrgs = false, personOptions = null, mediaMonitor = false, personOfInterest = null,
    orgOfInterest = null, orgsOfInterest = null, electionFactsTracker = false, trackerType = ["international politics"] }) {

    const getPastWeekStartDate = () => {
        const now = new Date();
        // Subtract 7 days from the current time
        const past7Days = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
        return past7Days.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };


    const getDefaultStartDate = () => {
        const now = new Date();
        const past24Hours = new Date(now.getTime() - (24 * 60 * 60 * 1000)); // Subtract 24 hours from the current time
        return past24Hours.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const getDefaultEndDate = () => {
        const now = new Date();
        const tomorrow = new Date(now.setDate(now.getDate() + 2));
        return tomorrow.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const [numResults, setNumResults] = useState(10);
    const [hasMoreResults, setHasMoreResults] = useState(true);

    const handleLoadMore = async () => {
        const newNumResults = numResults + 10;
        setNumResults(newNumResults);
        await fetchData(newNumResults);
    };

    // Insert code to make initial call to ElasticSearch
    // Make request to Flask API to store the query; that's passed to Flask app which creates and calls the query

    const defaultFilters = {
        query: "",
        sources: [],
        countries: [],
        topics: [],
        date_published: excludePast24Hours ? [getPastWeekStartDate(), getDefaultEndDate()] : [getDefaultStartDate(), getDefaultEndDate()],
        links: [],
        tracker_type: [],
        article_id: [],
        latest: true,
        set_size: numResults
    };


    const [tableData, setTableData] = useState([]); //tableData will always be displayed in the data table; setTableData() needs to be called by handleSearchClick
    const [isLoading, setIsLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    const [isInitialLoad, setIsInitialLoad] = useState(true);


    const [sourceOptions, setSourceOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);


    const { addCustomTracker, removeCustomTracker, doesTrackerNameExist } = useFirestore();

    useEffect(() => {
        // If sourceOptions are provided as a prop, set them directly
        if (propSourceOptions) {
            setSourceOptions(propSourceOptions);
        } else {
            // Otherwise, fetch the source options as before
            const fetchFilterOptions = async () => {
                try {
                    // const dict = {fringe: true}
                    const response = await axios.post('https://fopotracking.ue.r.appspot.com/aggregations', {
                        fields: ['website_title', 'language', 'country'],
                        fringe: fringeTracker,
                        tracker_type: trackerType
                    });
                    if (response.data) {
                        setSourceOptions(response.data.website_title.map(source => ({ label: source, value: source })));
                        // console.log("L115");
                        // console.log(response.data.website_title.map(source => ({ label: source, value: source })));
                        setLanguageOptions(response.data.language.map(language => ({ label: language, value: language })));
                        setCountryOptions(response.data.country.map(country => ({ label: country, value: country })));
                    }
                } catch (error) {
                    console.error('Error fetching filter options:', error);
                }
            };
            fetchFilterOptions();
        }
    }, [propSourceOptions]); // Add propSourceOptions as a dependency


    useEffect(() => {
        // If sourceOptions are provided as a prop, set them directly
        if (propOrgOptions) {
            setOrgOptions(propOrgOptions);
        }
    }, [propOrgOptions]); // Add propSourceOptions as a dependency

    // Merge baseRequest with defaultFilters
    const initialFilters = { ...defaultFilters, ...baseRequest };

    // Use initialFilters to initialize the filters state
    const [filters, setFilters] = useState(initialFilters);

    // Update filters method
    const updateFilters = (newFilters) => {
        setFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
    };

    useEffect(() => {
        fetchData();
    }, []); // This will call fetchData on component mount

    // Watch how this behaves when a query returns no results; might need to modify it
    useEffect(() => {
        setIsLoading(tableData.length === 0);
    }, [tableData]);


    const fetchData = async (resultsToFetch = numResults) => {
        try {

            let combinedQuery;
            // Combine preset_query with the user's query if preset_query is provided
            if (preset_query) { // Check if preset_query exists
                combinedQuery = preset_query + " " + filters.query;
            } else {
                combinedQuery = filters.query;
            }

            // Update the filters object with the combinedQuery
            const updatedFilters = { ...filters, query: combinedQuery, set_size: resultsToFetch, 'text': mediaMonitor };

            // console.log("L171 FILTERS ARE", updatedFilters)

            const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', updatedFilters);
            // const response = await axios.post('http://localhost:5000/search', updatedFilters);

            setFilters(currentFilters => ({ ...currentFilters, latest: false }));

            let records = [];

            response['data'].forEach(item => {
                const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary, text, sentiment_compound, charged, candidate_sentiment, c2pa_creds, basic_emotion } = item['source'];
                const uniqueId = item['id']; // Adjust this according to your response structure
                // console.log("UNIQUE ID: ", uniqueId)
                // console.log("ITEM IS", item)
                let date = new Date(item['source']['date_published']);
                records.push({
                    "article_id": uniqueId, // Add the unique ID here
                    "date_time_published_utc": item['source']['date_published'].replace(" ", "T") + "+00:00",
                    "date_published": getDatePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                    "time_published": getTimePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                    "timezone_name": new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1],
                    "link": item['source']['link'],
                    "word_count": word_count,
                    "title": title,
                    "source_url": source_url,
                    "country": country,
                    "language": language,
                    "source": website_name,
                    "country_rank": country_rank,
                    "site_rank": site_rank,
                    "topics": topics,
                    "locations": locations,
                    "organizations": organizations,
                    "persons": persons,
                    "summary": summary,
                    "text": text || null,
                    "cluster_id": item['source']['Cluster_ID'] || null,
                    "image_link": item['source']['image'] || null,
                    "sentiment_compound": sentiment_compound || null, // Provide a default value if compound_sentiment is missing
                    "charged": charged || null, //Default to No if compound_sentiment is missing
                    "candidate_sentiment": candidate_sentiment || null,
                    "c2pa_creds": c2pa_creds || null,
                    "basic_emotion": basic_emotion || null
                })
            });

            // if (additionalResults === 0) {
            //     setTableData(records);
            // } else {
            //     setTableData(prevData => [...prevData, ...records]);
            // }

            setTableData(records);


            // console.log("table data is", records)
            if (records.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }
            if (records.length < resultsToFetch) {
                setHasMoreResults(false); // No more records to fetch
            } else {
                setHasMoreResults(true); // More records available
            }
        }
        catch (error) {
            console.error('Error fetching latest data:', error);
        }
    };

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Search by Source
    const [selectedSources, setselectedSources] = useState(null);
    const handleSourceChange = selectedOption => {
        setselectedSources(selectedOption);
        updateFilters({ sources: selectedOption.map(option => option.value) });
    };

    // Search by Organization
    const [selectedOrgs, setselectedOrgs] = useState(null);
    const handleOrgChange = selectedOption => {
        setselectedOrgs(selectedOption);
        updateFilters({ organizations: selectedOption.map(option => option.value) });
    };

    // Search by Language
    const [selectedLanguages, setSelectedLanguages] = useState(null);
    const handleLanguageChange = selectedOption => {
        setSelectedLanguages(selectedOption);
        updateFilters({ languages: selectedOption.map(option => option.value) });
    };

    // Search by Country
    const [selectedCountries, setselectedCountries] = useState(null);
    const handleCountryChange = selectedOption => {
        setselectedCountries(selectedOption);
        updateFilters({ countries: selectedOption.map(option => option.value) });
    };


    // Search by Sentiment
    const [selectedSentiments, setSelectedSentiments] = useState(null);
    const handleSentimentChange = selectedOption => {
        setSelectedSentiments(selectedOption);
        const selectedRanges = selectedOption.map(option => mapSentimentToRange(option.value));
        const minRange = Math.min(...selectedRanges.map(range => range[0]));
        const maxRange = Math.max(...selectedRanges.map(range => range[1]));
        updateFilters({ sentiment_compound: [minRange, maxRange] });
    };
    const sentimentOptions = [
        "Strongly Positive",
        "Moderately Positive",
        "Neutral",
        "Moderately Negative",
        "Strongly Negative"
    ].map(topic => ({ label: topic, value: topic }));
    const mapSentimentToRange = (text) => {
        switch (text) {
            case "Strongly Negative":
                return [-1.0, -0.6];
            case "Moderately Negative":
                return [-0.6, -0.2];
            case "Neutral":
                return [-0.2, 0.2];
            case "Moderately Positive":
                return [0.2, 0.6];
            case "Strongly Positive":
                return [0.6, 1.0];
            default:
                return [null, null]; // default case
        }
    };

    // Search by Person
    const [selectedPersons, setselectedPersons] = useState(null);
    const handlePersonChange = selectedOption => {
        setselectedPersons(selectedOption);
        updateFilters({ persons: selectedOption.map(option => option.value) });
    };

    // Search by Fact Check Type
    const [selectedFactCheckType, setselectedFactCheckType] = useState(null);
    const handleFactCheckTypeChange = selectedOption => {
        setselectedFactCheckType(selectedOption);
        updateFilters({ fact_check_category: selectedOption.map(option => option.value) });
    };
    const factCheckTypeOptions = [
        "Election Processes",
        "Election Outcomes",
        "Candidate Statements",
        "Campaign Messaging"
    ].sort().map(topic => ({ label: topic, value: topic }));


    // Search by Topic
    const [selectedTopics, setselectedTopics] = useState(null);
    const handleTopicChange = selectedOption => {
        setselectedTopics(selectedOption);
        updateFilters({ topics: selectedOption.map(option => option.value) });
    };
    const topicOptions = [
        "Arts, Culture, Entertainment and Media",
        "Conflict, War and Peace",
        "Crime, Law and Justice",
        "Disaster, Accident and Emergency Incident",
        "Economy, Business and Finance",
        "Education",
        "Environment",
        "Health",
        "Human Interest",
        "Labour",
        "Lifestyle and Leisure",
        "Politics",
        "Religion",
        "Science and Technology",
        "Society",
        "Sport",
        "Weather"
    ].sort().map(topic => ({ label: topic, value: topic }));

    const handleTimeRangeChange = selectedOption => {
        setSelectedTimeRange(selectedOption);
        const now = new Date();
        let startDate = now; // Fallback to current date
        let endDate = new Date(now.setDate(now.getDate() + 1)); // Tomorrow's date as the end date

        switch (selectedOption.value) {
            case "Past 24 Hours":
                startDate = new Date(new Date().setDate(now.getDate() - 1));
                break;
            case "Past Week":
                startDate = new Date(new Date().setDate(now.getDate() - 7));
                break;
            case "Past Month":
                startDate = new Date(new Date().setMonth(now.getMonth() - 1));
                break;
            case "Past 4 Months":
                startDate = new Date('2024-01-01T00:00:00');
                break;
            case "Custom Range":
                // Intentionally left blank to allow for manual date entry
                return; // Exit the function to prevent automatic date updates
            default:
                break;
        }

        // Format the start and end dates
        const formattedStartDate = formatDateToString(startDate);
        const formattedEndDate = formatDateToString(endDate);

        // Update state and filters with the new start and end dates
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        updateFiltersWithDates(formattedStartDate, formattedEndDate);
    };

    const updateFiltersWithDates = (startDate, endDate) => {
        const newFilters = { ...filters, date_published: [startDate, endDate] };
        setFilters(newFilters);
        // If you're fetching data right after updating filters, consider calling fetchData here
        fetchData(newFilters);
    };

    const formatDateToString = (date) => {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const timeRangeOptions = excludePast24Hours ? [
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
        { label: "Custom Range", value: "Custom Range" },
    ] : [
        { label: "Past 24 Hours", value: "Past 24 Hours" },
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
        { label: "Custom Range", value: "Custom Range" },
    ];
    const [selectedTimeRange, setSelectedTimeRange] = useState(
        excludePast24Hours
            ? timeRangeOptions.find(option => option.value === "Past Week")
            : timeRangeOptions.find(option => option.value === "Past 24 Hours")
    );


    //Custom Tracker Name
    const [trackerName, setTrackerName] = useState('');
    const [trackerNameError, setTrackerNameError] = useState("Enter a tracker name.");

    const handleTrackerNameChange = (e) => {
        const inputTrackerName = e.target.value;
        setTrackerName(inputTrackerName); // Set the name as is while typing

        // Apply trimming only when validating
        const trimmedTrackerName = inputTrackerName.trim();

        if (trimmedTrackerName === '') {
            setTrackerNameError("Enter a tracker name.");
        }
        else if (doesTrackerNameExist(trimmedTrackerName)) {
            setTrackerNameError("Enter a unique tracker name.");
        }
        else {
            setTrackerNameError('');
        }
    };

    //Semantic Search
    const [selectedSemanticSearch, setSemanticSearch] = useState('');
    const handleSemanticSearchChange = (e) => {
        const searchValue = e.target.value;
        setSemanticSearch(searchValue);

        // Update the 'query' field of filters
        updateFilters({ query: searchValue });
    };


    // Search by Date
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const validateDateFormat = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        return regex.test(date);
    };

    const validateDateRange = (start, end) => {
        if (!start) {
            return true; // Assuming a start date is required for validation to pass
        }
        const defaultEndDate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const endDate = end ? new Date(end) : new Date(defaultEndDate);
        const startDate = new Date(start);

        return startDate < endDate;
    };

    const [dateError, setDateError] = useState('');

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);

        if (!newStartDate || validateDateFormat(newStartDate)) {
            updateFilters({ date_published: [newStartDate, endDate] });
        }

        if (newStartDate === '') {
            setDateError('');
            return;
        }

        if (!validateDateFormat(newStartDate)) {
            setDateError('Please use MM/DD/YYYY.');
        }
        else if (!validateDateRange(newStartDate, endDate)) {
            setDateError('Start date must be before end date.');
        }
        else {
            setDateError('');
            updateFilters({ date_published: [newStartDate, endDate] });
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);

        if (!newEndDate || validateDateFormat(newEndDate)) {
            updateFilters({ date_published: [startDate, newEndDate] });
        }

        if (newEndDate === '') {
            setDateError('');
            return;
        }

        if (!validateDateFormat(newEndDate)) {
            setDateError('Please use MM/DD/YYYY.');
        } else if (!validateDateRange(startDate, newEndDate)) {
            setDateError('Start date must be before end date.');
        } else {
            setDateError('');
            updateFilters({ date_published: [startDate, newEndDate] });
        }
    };

    const handleSearchClick = async () => {
        // Reset numResults to its default value
        setNumResults(10);
        setIsInitialLoad(false);

        // Reset isLoading and noResults state
        setIsLoading(true);
        setNoResults(false);

        try {
            await fetchData(10); // Pass 10 as the initial number of results to fetch
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };


    const handleRefreshClick = async () => {
        handleSearchClick();
    };


    const [showNotificationBanner, setShowNotificationBanner] = useState(false);
    const [bannerText, setBannerText] = useState('');

    useEffect(() => {
        if (trackerName === '') {
            setTrackerNameError("Enter a tracker name.");
        } else if (doesTrackerNameExist(trackerName)) {
            setTrackerNameError("Enter a unique tracker name.");
        } else {
            setTrackerNameError('');
        }
    }, [trackerName, doesTrackerNameExist]); // Add dependencies here


    const handleCreateTrackerClick = async () => {
        baseRequest = {
            "countries": selectedCountries ? selectedCountries.map(country => country.label) : [],
            "date_published": [null, null],
            "languages": selectedLanguages ? selectedLanguages.map(language => language.label) : [],
            "query": selectedSemanticSearch,
            "sources": selectedSources ? selectedSources.map(source => source.label) : [],
            "organizations": selectedOrgs ? selectedOrgs.map(source => source.label) : [],
            "topics": selectedTopics ? selectedTopics.map(topic => topic.label) : []
        }

        const newTracker = {
            "trackerTitle": trackerName,
            "trackerParams": baseRequest
        };

        try {
            await addCustomTracker(newTracker)
            setBannerText("Tracker Created!")
            setTrackerName('')
        }
        catch (error) {
            setBannerText("Error:", error)
        }
        setShowNotificationBanner(true); // Show the success banner
        setTimeout(() => setShowNotificationBanner(false), 3000);
    };

    // Show GridLoader while data queries are loading
    // Might need to move this to DataTable depending on how querying
    // works out and interacts with isLoading parameter
    // in handleSearchClick, can also set isLoading to true at beginning
    // and then change it to false when loading is done, but this would require
    // just 

    // Common JSX block for the Sidebar
    const sidebar = (
        <div>
            {showSideBar && (
                <div className="sidebar">
                    <SideBar
                        sourceOptions={sourceOptions}
                        selectedSources={selectedSources}
                        onSourceChange={handleSourceChange}

                        orgOptions={orgOptions}
                        selectedOrg={selectedOrgs}
                        onOrgChange={handleOrgChange}
                        showOrgs={showOrgs}

                        languageOptions={languageOptions}
                        selectedLanguages={selectedLanguages}
                        onLanguageChange={handleLanguageChange}
                        languageSearch={languageSearch}

                        countryOptions={countryOptions}
                        selectedCountries={selectedCountries}
                        onCountryChange={handleCountryChange}
                        countrySearch={countrySearch}

                        topicOptions={topicOptions}
                        selectedTopics={selectedTopics}
                        onTopicChange={handleTopicChange}

                        sentimentOptions={sentimentOptions}
                        selectedSentiments={selectedSentiments}
                        onSentimentChange={handleSentimentChange}
                        showSentiment={showSentiment}

                        timeRangeOptions={timeRangeOptions}
                        selectedTimeRange={selectedTimeRange}
                        onTimeRangeChange={handleTimeRangeChange}

                        selectedSemanticSearch={selectedSemanticSearch}
                        onSemanticSearchChange={handleSemanticSearchChange}

                        startDate={startDate}
                        onStartDateChange={handleStartDateChange}
                        endDate={endDate}
                        onEndDateChange={handleEndDateChange}
                        dateError={dateError}

                        congressTracker={congressTracker}

                        personOptions={personOptions}
                        selectedPersons={selectedPersons}
                        onPersonChange={handlePersonChange}

                        customTrackerCreator={customTrackerCreator}
                        trackerName={trackerName}
                        onTrackerNameChange={handleTrackerNameChange}
                        trackerNameError={trackerNameError}

                        handleSearchClick={handleSearchClick}
                        handleRefreshClick={handleRefreshClick}
                        handleCreateTrackerClick={handleCreateTrackerClick}

                        electionFactsTracker={electionFactsTracker}
                        selectedFactCheckType={selectedFactCheckType}
                        onFactCheckChange={handleFactCheckTypeChange}
                        factCheckTypeOptions={factCheckTypeOptions}
                    />
                </div>
            )}
        </div>
    );

    // Conditionally render DataTable content based on noResults and isLoading
    let dataTableContent;

    if (!noResults && isLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else if (noResults && isLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br />
                <h2>No results found.</h2>
            </div>
        );
    }
    else {
        dataTableContent = (
            <>
                {showNotificationBanner && (
                    <div className="notification-banner">
                        <h3>{bannerText}</h3>
                    </div>
                )}
                <div className="data-table">
                    <div>
                        <DataTable
                            data={tableData}
                            semanticSearch={selectedSemanticSearch}
                            countryColumn={countryColumn}
                            languageColumn={languageColumn}
                            showSentiment={showSentiment}
                            showCluster={showCluster}
                            showCharged={showCharged}
                            personOfInterest={personOfInterest}
                            orgOfInterest={orgOfInterest}
                            orgsOfInterest={orgsOfInterest}
                            isInitialLoad={isInitialLoad}
                        />
                    </div>
                    {!shareArticle && (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                            {hasMoreResults ? (
                                <button className="btn-custom btn-charcoal-gray" onClick={handleLoadMore}>Load More</button>
                            ) : (
                                <h4>Showing all relevant results.</h4>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    }

    // Render the combined JSX
    return (
        <div className="searchable-container">
            {sidebar}
            {dataTableContent}
        </div>
    );
};

export default SearchableTable;