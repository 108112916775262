import React, { useEffect, useState } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "../../firebase";
import { useUser } from "../../contexts/UserContext";
import { Link } from 'react-router-dom';
import "../GlobalStyles.css";

const SignIn = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    // Clean up the subscription when the component is unmounted
    return () => unsubscribe();
  }, []);

  // Render a loading message if still loading
  if (loading) {
    return <div>Loading...</div>;
  }

  const getFirstName = () => {
    if (user && user.displayName) {
      const fullName = user.displayName;
      const firstName = fullName.split(" ")[0];
      return firstName;
    }
    return "";
  };

  const handleSignOut = async () => {
    const auth = getAuth(firebaseApp);
    signOut(auth);
  };

  return (
    <div>
      {user ? (
        <div className="d-flex align-items-center">
          <span className="me-2">
            {getFirstName() ? `Hello, ${getFirstName()}!` : "Hello!"}
          </span>
          <button className="btn-fixture btn-mid-gray ml-1" onClick={handleSignOut}>
            Sign Out
          </button>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to="/login">
            <button className="btn-fixture btn-charcoal-gray"> Sign In </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default SignIn;
