import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD5dlLaO0MGtQStY4IdO7IgRwgFghxHasU",
    authDomain: "fopotracking.firebaseapp.com",
    databaseURL: "https://fopotracking-default-rtdb.firebaseio.com",
    projectId: "fopotracking",
    storageBucket: "fopotracking.appspot.com",
    messagingSenderId: "774746314508",
    appId: "1:774746314508:web:91a703483f8ccb446a1b29",
    measurementId: "G-B1C9PTJVRJ"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(firebaseApp);

export { firebaseApp, firestoreDB };
