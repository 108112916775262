import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function ElectionFacts() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const buttonContainerStyle = isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : { textAlign: 'center' };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', // One column on mobile, two columns otherwise
        textAlign: 'center',
        gap: '5px', // Space between columns
    };

    return (
        <div>
            <div className="text-content">
                <h1 style={{ textAlign: 'center' }}>Election Facts</h1>
                <h3 style={{ textAlign: 'center' }}>Fact-check your election news, all in one place.</h3>
                <h3 style={{ textAlign: 'center' }}>Click a flag to get started.</h3>
                <br />
            </div>
            <div>
                <div style={gridContainerStyle}>
                    <div>
                        <h3>Illinois</h3>
                        <Link to="/election_facts/illinois" className="btn-link">
                            <img src="https://i.imgur.com/Emf9on4.png" alt="IL_flag" style={{ maxWidth: '90%', maxHeight: '70%' }} />
                        </Link>
                    </div>
                    <div>
                        <h3>Utah</h3>
                        <Link to="/election_facts/utah" className="btn-link">
                            <img src="https://imgur.com/i6yCh5T.png" alt="UT_flag" style={{ maxWidth: '90%', maxHeight: '70%' }} />
                        </Link>
                    </div>
                    <div>
                        <h3>Virginia</h3>
                        <Link to="/election_facts/virginia" className="btn-link">
                            <img src="https://imgur.com/2cUPhq7.png" alt="VA_flag" style={{ maxWidth: '90%', maxHeight: '70%' }} />
                        </Link>
                    </div>
                    <div>
                        <h3>United States of America</h3>
                        <Link to="/election_facts/usa" className="btn-link">
                            <img src="https://i.imgur.com/aEupB4m.png" alt="USA_flag" style={{ maxWidth: '90%', maxHeight: '70%' }} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
