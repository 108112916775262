import React from 'react';
import "./HeaderFooter.css"

function Footer() {
    return (
        <footer className="footer bg-dark">
            <div className="container">
                <img src="/site_logo_2_white.png" alt="VerbaAI Logo" className="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;
