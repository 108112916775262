// Context to share rtdb across VerbaAI
// Handles fetching of rtdb data upon page load
// tableData set in pages/Home.js

import React, { createContext, useState, useEffect, useContext } from "react";

const TableDataContext = createContext();

export const TableDataProvider = ({ children }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const jsonUrl = 'https://storage.googleapis.com/rtdb/search_results%20(2).json';

    fetch(jsonUrl)
      .then((response) => response.json())
      .then((data) => {
        let records = data.map(item => {
          return {
            "date_published": `${(new Date(item.source.date_published).getMonth() + 1).toString().padStart(2, '0')}/${new Date(item.source.date_published).getDate().toString().padStart(2, '0')}/${new Date(item.source.date_published).getFullYear()}`,
            "time_published": `${new Date(new Date(item.source.date_published).getTime() - (5 * 60 * 60 * 1000)).getHours().toString().padStart(2, '0')}:${(Math.ceil(new Date(new Date(item.source.date_published).getTime() - (5 * 60 * 60 * 1000)).getMinutes() / 10) * 10).toString().padStart(2, '0').substring(0, 2)}`,
            "link": item.source.link,
            "word_count": item.source.word_count,
            "title": item.source.title,
            "source_url": item.source.source_url,
            "country": item.source.country,
            "language": item.source.language,
            "source": item.source.website_name,
            "country_rank": item.source.country_rank,
            "site_rank": item.source.site_rank,
            "topics": item.source.topics,
            "locations": item.source.locations,
            "organizations": item.source.organizations,
            "persons": item.source.persons,
            "summary": item.source.summary
          };
        });

        records.sort((a, b) => {
          const dateA = new Date(a.date_published);
          const dateB = new Date(b.date_published);
          return dateB - dateA;
        });

        setTableData(records);
      })
      .catch((error) => {
        console.error('Error fetching JSON data:', error);
      });
  }, []);

  return (
    <TableDataContext.Provider value={{ tableData, setTableData }}>
      {children}
    </TableDataContext.Provider>
  );
};


export const useTableData = () => {
  const context = useContext(TableDataContext);
  if (!context) {
    throw new Error("useTableData must be used within a TableDataProvider");
  }
  return context;
};

export default TableDataContext;
