import SearchableTable from '../search/SearchableTable.js';
import '../GlobalStyles.css';
import '../search/Search.css';

const ShareArticle = () => {
    const fullUrl = window.location.href;
    const baseUrl = window.location.origin + "/article/";
    const articleId = fullUrl.includes(baseUrl) ? fullUrl.split(baseUrl)[1] : null;

    const initialFilters = {
        article_id: [articleId],
        date_published: ["01/01/2000", "01/01/3000"]
    };

    return (
        <div classname="data-table">
            <SearchableTable showCharged={true} baseRequest={initialFilters} showSideBar={false} shareArticle={true} />
        </div>
    );
};

export default ShareArticle;
