import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";


const OutcomeOneTracker = () => {

    const filters = {
        "languages": ["English"],
        "tracker_type": ["outcome one"]
    }

    const customSourceOptions = [
        { label: 'Politico - politico.com', value: 'politico.com' },
        { label: 'MeriTalk - meritalk.com', value: 'meritalk.com' },
        { label: 'Roll Call - rollcall.com', value: 'rollcall.com' },
        { label: 'FedScoop - fedscoop.com', value: 'fedscoop.com' },
        { label: 'GovExec - govexec.com', value: 'govexec.com' },
        { label: 'ExecutiveGov - executivegov.com', value: 'executivegov.com' },
        { label: 'Federal News Network - federalnewsnetwork.com', value: 'federalnewsnetwork.com' },
        { label: 'Nextgov - nextgov.com', value: 'nextgov.com' },
        { label: 'Federal Times - federaltimes.com', value: 'federaltimes.com' },
        { label: 'FedWeek - fedweek.com', value: 'fedweek.com' },
        { label: 'Real Clear Politics - realclearpolitics.com', value: 'realclearpolitics.com' },
        { label: 'SBA - sba.gov', value: 'sba.gov' },
        { label: 'Small GovCon - smallgovcon.com', value: 'smallgovcon.com' },
        { label: 'Biz Journals - bizjournals.com', value: 'bizjournals.com' },
        { label: 'GovCon Wire - govconwire.com', value: 'govconwire.com' },
        { label: 'Reuters - reuters.com', value: 'reuters.com' },
        { label: 'BBC - bbc.com', value: 'bbc.com' },
        { label: 'Axios - axios.com', value: 'axios.com' },
        { label: 'CNET - cnet.com', value: 'cnet.com' },
        { label: 'NGA - nga.mil', value: 'nga.mil' },
        { label: 'Technology Review - technologyreview.com', value: 'technologyreview.com' },
        { label: 'Fast Company - fastcompany.com', value: 'fastcompany.com' },
        { label: 'Ars Technica - arstechnica.com', value: 'arstechnica.com' },
        { label: 'Dark Reading - darkreading.com', value: 'darkreading.com' },
        { label: 'Wired - wired.com', value: 'wired.com' },
        { label: 'TechCrunch - techcrunch.com', value: 'techcrunch.com' },
        { label: 'SciTech Daily - scitechdaily.com', value: 'scitechdaily.com' },
        { label: 'TechXplore - techxplore.com', value: 'techxplore.com' },
        { label: 'The Record Media - therecord.media', value: 'therecord.media' },
        { label: 'Gizmodo - gizmodo.com', value: 'gizmodo.com' },
        { label: 'QZ - qz.com', value: 'qz.com' },
        { label: 'Scientific American - scientificamerican.com', value: 'scientificamerican.com' },
        { label: 'National Geographic - nationalgeographic.com', value: 'nationalgeographic.com' },
        { label: 'Air Force Mag - airforcemag.com', value: 'airforcemag.com' },
        { label: 'AUSA - ausa.org', value: 'ausa.org' },
        { label: 'C4ISRNET - c4isrnet.com', value: 'c4isrnet.com' },
        { label: 'Defense News - defensenews.com', value: 'defensenews.com' },
        { label: 'Breaking Defense - breakingdefense.com', value: 'breakingdefense.com' },
        { label: 'Real Clear Defense - realcleardefense.com', value: 'realcleardefense.com' },
        { label: 'Army - army.mil', value: 'army.mil' },
        { label: 'Navy - navy.mil', value: 'navy.mil' },
        { label: 'Space News - spacenews.com', value: 'spacenews.com' },
        { label: 'Navy Times - navytimes.com', value: 'navytimes.com' },
        { label: '19FortyFive - 19fortyfive.com', value: '19fortyfive.com' },
        { label: 'Military Times - militarytimes.com', value: 'militarytimes.com' },
        { label: 'Defense Scoop - defensescoop.com', value: 'defensescoop.com' },
        { label: 'Defense One - defenseone.com', value: 'defenseone.com' },
        { label: 'AP News - apnews.com', value: 'apnews.com' },
        { label: 'Newsbreak - newsbreak.com', value: 'newsbreak.com' },
        { label: 'US News - usnews.com', value: 'usnews.com' },
        { label: 'NPR - npr.org', value: 'npr.org' },
        { label: 'PBS - pbs.org', value: 'pbs.org' },
        { label: 'Vox - vox.com', value: 'vox.com' },
        { label: 'CNBC - cnbc.com', value: 'cnbc.com' }
    ];

    const { user } = useUser();

    if (!user) {
        return (
            <div>
                <h1 style={{ textAlign: 'center' }}>outcome/one newsfeed</h1>
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
                <SearchableTable baseRequest={filters} sourceOptions={customSourceOptions} />
            </div>
        );
    }

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>outcome/one newsfeed</h1>
            <SearchableTable baseRequest={filters} sourceOptions={customSourceOptions} />
        </div>
    );
};

export default OutcomeOneTracker;
