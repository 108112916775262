import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const Fringe = () => {
    const { user } = useUser();
    const filters = { "fringe": true };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Fringe Media Tracker</h1>
            <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                NOTE: This tracker contains articles from media outlets known to share conspiracy theories. 
            </Alert>
            <Tabs defaultActiveKey="stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="stories" title="Breaking News">
                    <div className="searchable-container">
                        <SearchableCluster baseRequest={filters} selectedSortType="Last Updated" fringeCluster={true}/>
                    </div>
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable showCharged={true} baseRequest={filters} fringeTracker={true}/>
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors emerging narratives from fringe media outlets. The outlets included in this tracker are known to share conspiracy theories, and should be carefully vetted.
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Fringe;
