import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import '../GlobalStyles.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    zoomPlugin
);

// Define a color palette
const colorPalette = [
    'rgb(255, 99, 132)',   // Red
    'rgb(54, 162, 235)',   // Blue
    'rgb(255, 206, 86)',   // Yellow
    'rgb(75, 192, 192)',   // Teal
    'rgb(153, 102, 255)',  // Purple
    'rgb(255, 159, 64)',   // Orange
    'rgb(199, 199, 199)',  // Grey
    'rgb(83, 102, 255)',   // Indigo
    'rgb(255, 102, 255)',  // Pink
    'rgb(102, 255, 102)',  // Light Green
    // Add more colors as needed
];

const ElectViz = ({ articleTsne = {}, articleData = [] }) => {
    const chartRef = useRef(null);

    // State variables for time navigation
    const [currentTimestampIndex, setCurrentTimestampIndex] = useState(0);

    // Mapping from country names to colors
    const countryColorMapping = useMemo(() => {
        const uniqueCountries = Array.from(new Set(articleData.map(article => article.country || 'Unknown')));
        const colors = {};
        uniqueCountries.forEach((country, index) => {
            colors[country] = colorPalette[index % colorPalette.length];
        });
        return colors;
    }, [articleData]);

    // Extract and sort unique timestamps
    const sortedTimestamps = useMemo(() => {
        const timestamps = articleData.map((article) =>
            new Date(article.date_time_published_utc).getTime()
        );
        return Array.from(new Set(timestamps)).sort((a, b) => a - b);
    }, [articleData]);

    useEffect(() => {
        if (sortedTimestamps.length > 0) {
            setCurrentTimestampIndex(sortedTimestamps.length - 1);
        }
    }, [sortedTimestamps]);

    // Compute global min/max values for chart scales
    const globalMinMax = useMemo(() => {
        const allDataPoints = articleData
            .map((article) => {
                const embedding = articleTsne[article.article_id];
                return embedding ? { x: embedding[0], y: embedding[1] } : null;
            })
            .filter((point) => point !== null);

        if (allDataPoints.length === 0) {
            return { minX: -10, maxX: 10, minY: -10, maxY: 10 };
        }

        const xValues = allDataPoints.map((point) => point.x);
        const yValues = allDataPoints.map((point) => point.y);

        return {
            minX: Math.min(...xValues) - 3,
            maxX: Math.max(...xValues) + 3,
            minY: Math.min(...yValues) - 3,
            maxY: Math.max(...yValues) + 3,
        };
    }, [articleData, articleTsne]);

    // Prepare chart data
    const chartData = useMemo(() => {
        if (sortedTimestamps.length === 0) return { datasets: [] };
        const currentTimestamp = sortedTimestamps[currentTimestampIndex];

        const dataPoints = articleData
            .filter(
                (article) =>
                    new Date(article.date_time_published_utc).getTime() <= currentTimestamp
            )
            .map((article) => {
                const embedding = articleTsne[article.article_id];
                if (embedding) {
                    const country = article.country || 'Unknown';
                    const backgroundColor = countryColorMapping[country] || '#000000';
                    return {
                        x: parseFloat(embedding[0].toFixed(2)),
                        y: parseFloat(embedding[1].toFixed(2)),
                        title: article.title,
                        source: article.source,
                        country: country,
                        language: article.language,
                        article_id: article.article_id,
                        backgroundColor: backgroundColor,
                        borderColor: '#222222',
                    };
                }
                return null;
            })
            .filter((point) => point !== null);

        return {
            datasets: [
                {
                    label: 'Articles',
                    data: dataPoints,
                    backgroundColor: dataPoints.map((point) => point.backgroundColor),
                    borderColor: 'black',
                    borderWidth: 1,
                    pointRadius: 7,
                    pointHoverRadius: 9,
                },
            ],
        };
    }, [
        articleData,
        articleTsne,
        currentTimestampIndex,
        sortedTimestamps,
        countryColorMapping,
    ]);

    // Updated legend labels and tooltip labels
    const chartOptions = useMemo(
        () => ({
            animation: false,
            scales: {
                x: {
                    type: 'linear',
                    min: globalMinMax.minX,
                    max: globalMinMax.maxX,
                    ticks: { display: false },
                    grid: { display: false },
                },
                y: {
                    type: 'linear',
                    min: globalMinMax.minY,
                    max: globalMinMax.maxY,
                    ticks: { display: false },
                    grid: { display: false },
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        generateLabels: () =>
                            Object.keys(countryColorMapping).map((country) => ({
                                text: country,
                                fillStyle: countryColorMapping[country],
                                strokeStyle: '#222222',
                                lineWidth: 2,
                                hidden: false,
                            })),
                    },
                },
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                        title: (context) => context[0].raw.title,
                        label: (context) => {
                            const dataPoint = context.raw;
                            return [
                                `Country: ${dataPoint.country}`,
                                `Source: ${dataPoint.source}`,
                                `Language: ${dataPoint.language}`,
                            ];
                        },
                    },
                    titleFont: {
                        family: 'Afacad',
                        size: 20,
                        weight: '500',
                    },
                    bodyFont: {
                        family: 'Afacad',
                        size: 18,
                    },
                },
                zoom: {
                    zoom: {
                        drag: {
                            enabled: true,
                            borderColor: 'rgba(180, 180, 180, 0.8)',
                            backgroundColor: 'rgba(180, 180, 180, 0.15)',
                            borderWidth: 1,
                        },
                        mode: 'xy',
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: true,
            onClick: (event, elements) => {
                if (elements.length > 0) {
                    const element = elements[0];
                    const dataIndex = element.index;
                    const dataPoint =
                        chartData.datasets[element.datasetIndex].data[dataIndex];
                    if (dataPoint.article_id) {
                        window.open(
                            `https://verbaai.org/article/${dataPoint.article_id}`,
                            '_blank'
                        );
                    }
                }
            },
        }),
        [chartData.datasets, globalMinMax, countryColorMapping]
    );

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    const handleSliderChange = (event) => {
        setCurrentTimestampIndex(parseInt(event.target.value, 10));
    };

    const currentDateDisplay =
        sortedTimestamps.length > 0
            ? new Date(sortedTimestamps[currentTimestampIndex]).toLocaleString()
            : '';

    // Conditional rendering in JSX
    return (
        <div className="chart-container">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '10px', alignSelf: 'flex-end' }}>
                    <button className="btn-sidebar btn-charcoal-gray" onClick={handleResetZoom}>
                        Reset Zoom
                    </button>
                </div>
            </div>
            <Scatter ref={chartRef} data={chartData} options={chartOptions} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {sortedTimestamps.length > 0 && (
                    <>
                        <input
                            type="range"
                            min="0"
                            max={sortedTimestamps.length - 1}
                            value={currentTimestampIndex}
                            onChange={handleSliderChange}
                            style={{ width: '80%' }}
                        />
                        <div style={{ marginTop: '10px' }}>
                            <strong>Timestamp:</strong> {currentDateDisplay}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ElectViz;
