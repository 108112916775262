import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import '../GlobalStyles.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    zoomPlugin
);

const subClusterColorMapping = {
    '-1': 'rgb(192, 192, 192)', // Gray
    '0': 'rgb(255, 192, 203)', // Orchid
    '1': 'rgb(34, 139, 34)', // Forest Green
    '2': 'rgb(243, 103, 77)', // Salmon
    '3': 'rgb(17, 176, 179)', // Turquoise
    '4': 'rgb(255, 190, 28)', // Gold
    '5': 'rgb(122, 203, 57)', // Lime Green
    '6': 'rgb(46, 70, 155)', // Royal Blue
    '7': 'rgb(128, 0, 128)', // Purple
    '8': 'rgb(255, 105, 180)', // Hot Pink
    '9': 'rgb(255, 165, 0)', // Orange
    '10': 'rgb(242, 22, 22)', // Red
    '11': 'rgb(70, 130, 180)', // Steel Blue
    '12': 'rgb(139, 69, 19)', // Saddle Brown
    '13': 'rgb(255, 20, 147)', // Deep Pink
    '14': 'rgb(0, 128, 128)', // Teal
    '15': 'rgb(210, 105, 30)', // Chocolate
    '16': 'rgb(255, 99, 71)', // Tomato
    '17': 'rgb(75, 0, 130)', // Indigo
    '18': 'rgb(255, 228, 196)', // Bisque
    '19': 'rgb(154, 205, 50)', // Yellow Green
    '20': 'rgb(199, 21, 133)', // Medium Violet Red
    '21': 'rgb(0, 255, 255)', // Aqua
    '22': 'rgb(218, 112, 214)', // Orchid
    '23': 'rgb(255, 239, 213)', // Papaya Whip
    '24': 'rgb(64, 224, 208)', // Turquoise
};

// Function to generate a random color
const getRandomColor = () => {
    const random = () => Math.floor(Math.random() * 256);
    return `rgb(${random()}, ${random()}, ${random()})`;
};

const ClusterSimilarity = ({ articleTsne, articleData, clusterData }) => {
    const chartRef = useRef(null);

    const [currentTimestampIndex, setCurrentTimestampIndex] = useState(0);
    // Lift hiddenSubClusters state to the parent component
    const [hiddenSubClusters, setHiddenSubClusters] = useState({});

    const articleIdToSubClusterMap = useMemo(() => {
        const map = {};
        if (clusterData && clusterData.length > 0) {
            clusterData.forEach((cluster) => {
                if (Array.isArray(cluster.sub_clusters)) {
                    cluster.sub_clusters.forEach((subCluster) => {
                        map[subCluster.id] = subCluster.sub_cluster;
                    });
                }
            });
        }
        return map;
    }, [clusterData]);

    const sortedTimestamps = useMemo(() => {
        const timestamps = articleData.map((article) =>
            new Date(article.date_time_published_utc).getTime()
        );
        return Array.from(new Set(timestamps)).sort((a, b) => a - b);
    }, [articleData]);

    useEffect(() => {
        if (sortedTimestamps.length > 0) {
            setCurrentTimestampIndex(sortedTimestamps.length - 1);
        }
    }, [sortedTimestamps]);

    const globalMinMax = useMemo(() => {
        const allDataPoints = articleData
            .map((article) => {
                const embedding = articleTsne[article.article_id];
                return embedding ? { x: embedding[0], y: embedding[1] } : null;
            })
            .filter((point) => point !== null);

        if (allDataPoints.length === 0) {
            return { minX: -10, maxX: 10, minY: -10, maxY: 10 };
        }

        const xValues = allDataPoints.map((point) => point.x);
        const yValues = allDataPoints.map((point) => point.y);

        return {
            minX: Math.min(...xValues) - 3,
            maxX: Math.max(...xValues) + 3,
            minY: Math.min(...yValues) - 3,
            maxY: Math.max(...yValues) + 3,
        };
    }, [articleData, articleTsne]);

    const subClusterColors = useMemo(() => {
        const uniqueSubClusters = Array.from(
            new Set(Object.values(articleIdToSubClusterMap))
        ).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

        const colors = {};
        uniqueSubClusters.forEach((subClusterId) => {
            colors[subClusterId] =
                subClusterColorMapping[subClusterId] || getRandomColor();
        });
        return colors;
    }, [articleIdToSubClusterMap]);

    // Define toggleSubClusterVisibility function in parent component
    const toggleSubClusterVisibility = (subCluster) => {
        setHiddenSubClusters((prev) => ({
            ...prev,
            [subCluster]: !prev[subCluster],
        }));
    };

    const chartData = useMemo(() => {
        if (sortedTimestamps.length === 0) return { datasets: [] };
        const currentTimestamp = sortedTimestamps[currentTimestampIndex];

        const dataPoints = articleData
            .filter(
                (article) =>
                    new Date(article.date_time_published_utc).getTime() <= currentTimestamp
            )
            .map((article) => {
                const embedding = articleTsne[article.article_id];
                if (embedding) {
                    const subClusterId = articleIdToSubClusterMap[article.article_id];
                    // Filter out hidden subclusters
                    if (hiddenSubClusters[subClusterId]) {
                        return null; // Skip this data point
                    }
                    return {
                        x: parseFloat(embedding[0].toFixed(2)),
                        y: parseFloat(embedding[1].toFixed(2)),
                        title: article.title,
                        source: article.source,
                        country: article.country,
                        language: article.language,
                        article_id: article.article_id,
                        sub_cluster: subClusterId,
                        backgroundColor: subClusterColors[subClusterId] || '#000000',
                        borderColor: '#222222',
                    };
                }
                return null;
            })
            .filter((point) => point !== null);

        return {
            datasets: [
                {
                    label: 'Articles',
                    data: dataPoints,
                    backgroundColor: dataPoints.map((point) => point.backgroundColor),
                    borderColor: 'black',
                    borderWidth: 1,
                    pointRadius: 7,
                    pointHoverRadius: 9,
                },
            ],
        };
    }, [
        articleData,
        articleTsne,
        currentTimestampIndex,
        sortedTimestamps,
        articleIdToSubClusterMap,
        subClusterColors,
        hiddenSubClusters, // Include hiddenSubClusters as a dependency
    ]);

    // Disable the default legend in chartOptions
    const chartOptions = useMemo(
        () => ({
            animation: false,
            scales: {
                x: {
                    type: 'linear',
                    min: globalMinMax.minX,
                    max: globalMinMax.maxX,
                    ticks: { display: false },
                    grid: { display: false },
                },
                y: {
                    type: 'linear',
                    min: globalMinMax.minY,
                    max: globalMinMax.maxY,
                    ticks: { display: false },
                    grid: { display: false },
                },
            },
            plugins: {
                legend: {
                    display: false, // Disable the default legend
                },
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                        title: (context) => context[0].raw.title,
                        label: (context) => {
                            const dataPoint = context.raw;
                            const subNarrativeLabel =
                                dataPoint.sub_cluster === -1
                                    ? 'General'
                                    : `Subnarrative: ${dataPoint.sub_cluster}`;
                            return [
                                subNarrativeLabel,
                                `Source: ${dataPoint.source}`,
                                `Country: ${dataPoint.country}`,
                                `Language: ${dataPoint.language}`,
                            ];
                        },
                    },
                    titleFont: {
                        family: 'Afacad',
                        size: 20,
                        weight: '500',
                    },
                    bodyFont: {
                        family: 'Afacad',
                        size: 18,
                    },
                },
                zoom: {
                    zoom: {
                        drag: {
                            enabled: true,
                            borderColor: 'rgba(180, 180, 180, 0.8)',
                            backgroundColor: 'rgba(180, 180, 180, 0.15)',
                            borderWidth: 1,
                        },
                        mode: 'xy',
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: true,
            onClick: (event, elements) => {
                if (elements.length > 0) {
                    const element = elements[0];
                    const dataIndex = element.index;
                    const dataPoint =
                        chartData.datasets[element.datasetIndex].data[dataIndex];
                    if (dataPoint.article_id) {
                        window.open(
                            `https://verbaai.org/article/${dataPoint.article_id}`,
                            '_blank'
                        );
                    }
                }
            },
        }),
        [chartData.datasets, globalMinMax]
    );

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    const handleSliderChange = (event) => {
        setCurrentTimestampIndex(parseInt(event.target.value, 10));
    };

    const currentDateDisplay =
        sortedTimestamps.length > 0
            ? new Date(sortedTimestamps[currentTimestampIndex]).toLocaleString()
            : '';

    // Modified CustomLegend Component
    const CustomLegend = ({ subClusterColors, hiddenSubClusters, toggleSubClusterVisibility }) => {
        const legendItems = Object.keys(subClusterColors).map((subCluster) => {
            const color = subClusterColors[subCluster];
            const label = subCluster === '-1' ? 'General' : `Subnarrative ${subCluster}`;
            const isHidden = hiddenSubClusters[subCluster];

            return (
                <div
                    key={subCluster}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '5px',
                        opacity: isHidden ? 0.5 : 1,
                        cursor: 'pointer',
                        width: '15%',
                        margin: '5px',
                    }}
                    onClick={() => toggleSubClusterVisibility(subCluster)}
                >
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: color,
                            border: '1px solid #222',
                            marginRight: '10px',
                        }}
                    ></div>
                    <span>{label}</span>
                </div>
            );
        });

        return (
            <div
                style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    padding: '10px',
                    border: '1px solid #ccc',
                    marginTop: '10px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                    }}
                >
                    {legendItems}
                </div>
            </div>
        );
    };

    return (
        <div className="chart-container">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '10px', alignSelf: 'flex-end' }}>
                    <button className="btn-sidebar btn-charcoal-gray" onClick={handleResetZoom}>
                        Reset Zoom
                    </button>
                </div>
            </div>
            {/* Render the custom legend above the chart */}
            <CustomLegend
                subClusterColors={subClusterColors}
                hiddenSubClusters={hiddenSubClusters}
                toggleSubClusterVisibility={toggleSubClusterVisibility}
            />
            <Scatter ref={chartRef} data={chartData} options={chartOptions} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {sortedTimestamps.length > 0 && (
                    <>
                        <input
                            type="range"
                            min="0"
                            max={sortedTimestamps.length - 1}
                            value={currentTimestampIndex}
                            onChange={handleSliderChange}
                            style={{ width: '80%' }}
                        />
                        <div style={{ marginTop: '10px' }}>
                            <strong>Timestamp:</strong> {currentDateDisplay}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ClusterSimilarity;
