// This page allows users to sign in VerbaAI,
// either with an email/password or via Google Oauth

import React, { useState, useEffect, useContext } from 'react';
import { useUser } from "../../contexts/UserContext";
import { Link } from 'react-router-dom';  // Add this import at the top of your file

import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { firebaseApp } from "../../firebase";
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import '../GlobalStyles.css'


export default function Login() {
    const { user, setUser } = useUser();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(false); // New state variable to check if user is on sign-up view

    const [showAlert, setShowAlert] = useState(false); // State for alert visibility
    const [alertMessage, setAlertMessage] = useState(''); // State for alert message

    const [forgotPassword, setForgotPassword] = useState(false); //New state variable to check if user is on forgot password view

    function refreshPage() {
        // Refreshes the current page
        window.location.reload();
    }


    const handleEmailSignIn = async (e) => {
        e.preventDefault();
        const auth = getAuth(firebaseApp);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                setUser(user);
            })
            .catch((error) => {
                setAlertMessage(error.message.replace("Firebase: ", ""));
                setShowAlert(true); // Show the alert
            });
    };

    const handleEmailSignUp = async (e) => {
        e.preventDefault();
        const auth = getAuth(firebaseApp);

        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        // Check if the email is valid
        if (emailRegex.test(email)) {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    setUser(user);
                })
                .catch((error) => {
                    setAlertMessage(error.message.replace("Firebase: ", ""));
                    setShowAlert(true); // Show the alert
                });
        } else {
            // If email is not valid, set the alert message and show the alert
            setAlertMessage("Please enter a valid email address.");
            setShowAlert(true);
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        const auth = getAuth(firebaseApp);
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .catch((error) => {
                console.error(error);
            });
    };

    const handleForgotPassword = async (e) => {
        setForgotPassword(true);
    }

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        const auth = getAuth(firebaseApp);
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setAlertMessage("Password reset link sent to your email.");
                setShowAlert(true);
            })
            .catch((error) => {
                setAlertMessage(error.message.replace("Firebase: ", ""));
                setShowAlert(true);
            });
    };


    if (!user && !forgotPassword) {
        return (
            <div className="home-container">
                <div className="text-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {showAlert && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                            {alertMessage}
                        </Alert>
                    )}
                    <h1 style={{ textAlign: 'center' }}>{isSignUp ? "Sign up for VerbaAI" : "Sign in to VerbaAI"}</h1>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>

                        {isSignUp ? (
                            <button className="btn-custom btn-charcoal-gray mb-2 w-100" onClick={handleEmailSignUp}>
                                Sign up with Email
                            </button>
                        ) : (
                            <button className="btn-custom btn-charcoal-gray mb-2 w-100" onClick={handleEmailSignIn}>
                                Sign in with Email
                            </button>
                        )}

                        {!isSignUp && (
                            <div className="text-center">
                                <span onClick={handleForgotPassword} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                    Forgot password?
                                </span>
                            </div>
                        )}

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: '1.5rem' }}></div>
                            <h3>OR</h3>
                        </div>

                        <br />
                        <button className="btn-custom btn-mid-gray w-100" onClick={handleGoogleSignIn}>
                            {isSignUp ? "Sign up with Google" : "Sign in with Google"}
                        </button>


                        <div className="text-center mt-3">
                            {isSignUp ? (
                                <p>Already have an account? <span onClick={() => setIsSignUp(false)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Sign in.</span></p>
                            ) : (
                                <p>Don't have an account? <span onClick={() => setIsSignUp(true)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Sign up.</span></p>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    if (!user && forgotPassword) {
        return (
            <div className="home-container">
                <div className="text-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {showAlert && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                            {alertMessage}
                        </Alert>
                    )}
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Please enter the email associated with your VerbaAI account.</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <button className="btn-custom btn-charcoal-gray mr-1 mb-2" onClick={handlePasswordReset}>
                            {"Reset Password"}
                        </button>
                        <button className="btn-custom btn-mid-gray" onClick={refreshPage}>
                            {"Return to Sign In"}
                        </button>
                    </Form>
                </div>
            </div>
        )
    }

    return (
        <div className="home-container">
            <div className="text-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Welcome to VerbaAI!</h1>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/international" className="btn-link">
                        <button className="btn-custom btn-charcoal-gray">Get Started</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};