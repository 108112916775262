import React, { useState, useRef, useEffect } from 'react';
import '../GlobalStyles.css';
import GridLoader from 'react-spinners/GridLoader';
import { useUser } from "../../contexts/UserContext.js";
import { Link } from "react-router-dom";


const AskVerba = () => {
    const [inputValue, setInputValue] = useState('');
    const [apiResponse, setApiResponse] = useState('');
    const { user } = useUser();

    const [displayText, setDisplayText] = useState('');
    const [citationUrls, setCitationUrls] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [clearPage, setClearPage] = useState(true);

    // Function to convert line breaks in text to HTML <br /> tags
    const convertLineBreaksToHTML = (text) => {
        return text.replace(/\n/g, '<br />');
    };

    // Processes api response and updates relevant parameters
    const processResponse = () => {
        if (apiResponse && apiResponse.text && apiResponse.citations && apiResponse.documents) {
            // Generate docRefMap
            let refCounter = 1;
            apiResponse.documents.forEach(doc => {
                if (!docRefMap[doc.id]) {
                    docRefMap[doc.id] = refCounter++;
                }
            });

            setDisplayText(apiResponse && apiResponse.text && apiResponse.citations && apiResponse.documents
                ? convertLineBreaksToHTML(markCitations(apiResponse.text, apiResponse.citations, apiResponse.documents, docRefMap))
                : '')

            setCitationUrls(apiResponse && apiResponse.documents
                ? getCitationUrls(apiResponse.documents, docRefMap)
                : {})
        }
    }

    const apiKey1 = "CFSzUi9JXbDn4dwFQgan3RBhCq0vjC7NC0nBENoZ"
    const apiKey2 = "lTLT4uv8oa6EzFhj5lOYBP7SVw5iUjl7XwMyKWxI"
    const apiKey3 = "UYelytt8S3tBa1hq0n7DoVpOOII8SfZkRS3yvoAm"
    const apiKey4 = "9DUiXsKXIKlU5UoNpK7eujOiV6tJDX8fEbfCjsZ7"
    const apiKey5 = "t7sSLx0PLUYEA9Iq6OXfM4bMpMbWblBVhgbijvKn"
    const apiKey6 = "4MKtOnqeIE1K6s48K9mmTUQKPWuVYic3DwZNtTyC"
    const apiKey7 = "7ueKQawLxS7y68zxoTMtTOjyfqn1OtQ3LUl6S4Be"
    const apiKey8 = "5rc8ezfVZ1MQyv6X3LEemhCnB7PSmgbhjEsxyBIA"
    const apiKey9 = "G4xjqmOjO4pViUCj5kusbBFTBTG2291KLyJ4oWtk"
    const apiKey10 = "loSdELRWR9oOH56SMiX0yVu2CrMz323ppsDVbn70"
    const apiKey11 = "YskCCzHKQwUDatmbu5uqRd3G6909rzeH6rWjEtvg"
    const apiKey12 = "dPbryzFutMmW5Ctktb7l6q4dh2EkFjktC1qM3Kd0"
    const apiKey13 = "9idwa5bKQJ9wt2P87EehlrTKtX0nMqSVoLl4O91n"
    const apiKey14 = "Poc7RlmGBniFxmWQ3JQfVmCiijQqkVUvOvo6poE0"
    const apiKey15 = "ZWX8C0cT8Jkx8GBCRw2koXRE11SLQGQU1ygs9uDg"
    const apiKey16 = "cW69r0i90HH9OHKJTxAyg1mzOyk0LvSc9RdBFuPa"
    const apiKey17 = "MlFpKqewN3PJul9nsl6XwnBfGChCLnsTKe0F3vJT"
    const apiKey18 = "97IO7ARinSoPePM9XayW8n4ecFjIqJtHdNPeCUJf"
    const apiKey19 = "eoj9kAgtCYCFtk2hK9SJ9GZeSMYtBtGsJi9b00xr"
    const apiKey20 = "0K8tXpYmrXO9AQTBCSl5JxvYusZcD7dW9cKYIJnS"
    const apiKey21 = "DnH7hgkcMYPF18ZEVwCAY4ote1uuLVhoNX4ijfFk"
    const apiKey22 = "hwdthLNLGkfTAzdlh1q0HbOFAVHGMdOKE4YdNhkE"
    const apiKey23 = "jlyMf4oY0SQ1dm7WKGy2GqWsnVjO30Kys0z8UNlj"
    const apiKey24 = "ivOAKMS5Y3Tz7OX7Ti9sqGa1wgo6oUWmat7ok1Ml"
    const apiKey25 = "fuPauoqELj4S3dke7VaPx4UXuwrkduTD0wYw0Qpx"
    const apiKey26 = "HurQv0vVvY3MSwRsVbsZze01xOF90Ck2DY8c1xV1"
    const apiKey27 = "MdTmVKyx9jEa5AzAyKBOppQtFJtSSzhKJCOY2LT7"
    const apiKey28 = "vkBNkjRWzrmBTzNQwqlX7np39CVn3YJrNuocHJAo"
    const apiKey29 = "MDMs8PigSBSG7OUNfaZ77MOWdgflgpWoI7eOipo7"
    const apiKey30 = "Fxu6iuYXRlikWCuFWnCkUlNlz3rvupzfS8SMssOI"
    const apiKey31 = "YvFoOr8UC9GtMLo0vFO31VoZj0p51PL0ppx2e8oe"
    const apiKey32 = "Nv7gcnGO3VMpHsaDwO7APhwhYD1qdNN4D5pq2d99"
    const apiKey33 = "DOu5AMDaGX5PulHwAofrDkHpmiToVjlgAOM3ozpI"
    const apiKey34 = "1WDq6Wy9adR1DIhOip0t23xpm29l255irMWZiReV"
    const apiKey35 = "bKQFFFuRrMaConueZp1NTT5BhqPcUX1SPeF0WiYL"
    const apiKey36 = "jPQmZSzn0DjbSt0U2IU9qsKlSWvK850ZdpLKuJN6"
    const apiKey37 = "NhUQYzaWzcN8G82FTaM9OvwkrZw0VIo1vDWSXmz3"
    const apiKey38 = "NFuS3jIuMHSnAB9Dd2nKCdzDVDODAbmhDmo73bx4"
    const apiKey39 = "irgAIhbCS9bUkbUI8d3G20WAo9PS6cAXsPlVXqv1"

    const api_keys = [
        apiKey1, apiKey2, apiKey3, apiKey4, apiKey5,
        apiKey6, apiKey7, apiKey8, apiKey9, apiKey10,
        apiKey11, apiKey12, apiKey13, apiKey14, apiKey15,
        apiKey16, apiKey17, apiKey18, apiKey19, apiKey20,
        apiKey21, apiKey22, apiKey23, apiKey24, apiKey25,
        apiKey26, apiKey27, apiKey28, apiKey29, apiKey30,
        apiKey31, apiKey32, apiKey33, apiKey34, apiKey35,
        apiKey36, apiKey37, apiKey38, apiKey39
    ]


    // Add useEffect to process the API response after re-render
    useEffect(() => {
        processResponse();
    }, [apiResponse]);


    const handleSearchButtonClick = () => {
        setIsLoading(true);
        setClearPage(false);
        const url = 'https://api.cohere.ai/v1/chat';
        const randomIndex = Math.floor(Math.random() * api_keys.length);
        const apiKey = api_keys[randomIndex];
        // console.log("L110 api key is", apiKey)
        const data = {
            message: inputValue + "; Please only use sources from reliable news outlets, educational institutions, and government websites in crafting your response. Do not use Wikipedia. Use the most recent sources possible.",
            connectors: [{ id: "web-search" }]
        };

        // Ensure that fetch is returned
        return fetch(url, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setIsLoading(false);
                return response.json();
            })
            .then(data => {
                // console.log("API Response:", data); // Check the raw API response
                setApiResponse(data); // Update the API response
                // processResponse(); // Process the API response for display
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error during fetch operation:', error);
                setIsLoading(false);
                setApiResponse(`Error: ${error.message}`);
            });
    };

    // Function to mark text with citations and append reference numbers
    const markCitations = (text, citations, documents, docRefMap) => {
        let markedText = '';
        let lastIndex = 0;
        const sentenceRefs = {};

        // Map references in each sentence
        citations.forEach(citation => {
            citation.document_ids.forEach(docId => {
                const refNumber = docRefMap[docId];
                for (let i = citation.start; i <= citation.end; i++) {
                    if ((text[i] === '.' && text[i + 1] === ' ' && text[i + 2] && text[i + 2] === text[i + 2].toUpperCase()) || i === text.length - 1) {
                        if (!sentenceRefs[i]) {
                            sentenceRefs[i] = [];
                        }
                        if (!sentenceRefs[i].includes(refNumber)) {
                            sentenceRefs[i].push(refNumber);
                        }
                        break;
                    }
                }
            });
        });

        for (let i = 0; i < text.length; i++) {
            markedText += text[i];
            if (sentenceRefs[i]) {
                markedText += `<sup class="citation-ref">[${sentenceRefs[i].join(", ")}]</sup>`;
            }
        }

        return markedText;
    };

    // Define a function to extract the website name from the URL
    const extractWebsiteName = (url) => {
        // Implement logic to extract the website name from the URL
        // For example, you can split the URL by '/' and take the second part as the website name
        const urlParts = url.split('/');
        if (urlParts.length >= 3) {
            return urlParts[2]; // Assuming the website name is in the second part of the URL
        } else {
            return ''; // Return an empty string if the URL format is not as expected
        }
    };

    // Function to generate the citation number to URL and snippet mapping
    const getCitationUrls = (documents, docRefMap) => {
        let citationUrls = {};
        documents.forEach(doc => {
            const refNumber = docRefMap[doc.id];
            if (!citationUrls[refNumber]) {
                citationUrls[refNumber] = [];
            }

            const url = doc.url;
            const website_name = extractWebsiteName(url); // Define a function to extract the website name

            citationUrls[refNumber].push({
                url: doc.url,
                snippet: doc.snippet,
                title: doc.title, // Include the title here
                website_name: website_name // Include the website_name here
            });
        });
        return citationUrls;
    };

    // Define docRefMap in the main component scope
    const docRefMap = useRef({}).current;
    let refCounter = 1;
    if (apiResponse && apiResponse.documents) {
        apiResponse.documents.forEach(doc => {
            if (!docRefMap[doc.id]) {
                docRefMap[doc.id] = refCounter++;
            }
        });
    }


    if (!user) {
        return (
            <div className="home-container">
                <div className="text-content">
                    <h1 style={{ textAlign: 'center' }}>Ask VerbaAI</h1>
                    <br />
                    <h3 style={{ textAlign: 'center' }}>Meet your AI research assistant.</h3>
                    <div style={{ textAlign: 'center', maxWidth: '50vw', margin: '0 auto' }}>
                        When you enter a query, Ask VerbaAI will search the web, identify relevant information, and synthesize it to meet your needs. It's designed for quick, efficient responses to your questions about global news and events. Unlike traditional LLMs, Ask VerbaAI cites its sources, so you can easily verify its answers. <br /><br />Ask VerbaAI is available now to registered VerbaAI users.
                    </div>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        <Link to="/login" className="btn-link">
                            <button className="btn-custom btn-charcoal-gray mr-1 mb-2">Sign In/Sign Up</button>
                        </Link>
                        <Link to="/askdemo" className="btn-link">
                            <button className="btn-custom btn-mid-gray">See a Demo</button>
                        </Link>
                    </div>
                    <br />
                </div>
            </div>
        )
    }


    return (
        <div className="home-container">
            <div className="text-content">
                <h1 style={{ textAlign: 'center' }}>Ask VerbaAI</h1>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Type your message here..."
                    className="input-custom"
                />
                <br />
                <div style={{ textAlign: 'center' }}>
                    <button
                        onClick={handleSearchButtonClick}
                        className="btn-custom btn-charcoal-gray"
                    >
                        Search
                    </button>
                </div>
                <br />
                {isLoading && !clearPage && (
                    <div style={{ textAlign: "center" }}>
                        <GridLoader />
                        <br />
                        <strong><h2>Querying the web...</h2></strong>
                    </div>
                )}
                {!isLoading && !clearPage && (
                    <div className="rag-response">
                        {/* {JSON.stringify(apiResponse)} */}
                        <h2>Answer:</h2>
                        <div dangerouslySetInnerHTML={{ __html: displayText }} className="api-response"></div>
                        <div className="citation-urls">
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <strong>
                                    AI can make mistakes. Consider checking important information.
                                    <br />
                                    Responses are generated algorithmically and do not reflect VerbaAI's views or positions.
                                </strong>
                            </div>
                            <br />
                            <h2>References:</h2>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {Object.entries(citationUrls).map(([refNumber, refs]) => (
                                    <li key={refNumber}>
                                        {refs.map((ref, index) => (
                                            <div key={index} className="citation-detail">
                                                <p>
                                                    <strong>[{refNumber}] </strong>
                                                    <strong><a href={ref.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                        {ref.title} ({ref.website_name})
                                                    </a></strong>
                                                </p>
                                                <p className="citation-snippet">"{ref.snippet}"</p>
                                            </div>
                                        ))}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AskVerba;
