import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const ElectionNarratives = () => {
    const { user } = useUser();


    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>2024 U.S. Election Narratives</h1>
            <Tabs defaultActiveKey="usa-stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="usa-stories" title="USA Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"united_states": true}}
                            selectedSortType="Last Updated"
                            usaCluster={true}
                            trackerType={["us news"]}
                            preset_query='United States Election'
                        />
                    </div>
                </Tab>
                <Tab eventKey="global-stories" title="Global Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            selectedSortType="Last Updated"
                            preset_query='United States Election'
                        />
                    </div>
                </Tab>
                <Tab eventKey="china-stories" title="China Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"china": true}}
                            selectedSortType="Last Updated"
                            chinaCluster={true}
                            trackerType={["china news"]}
                            preset_query='United States Election'
                        />
                    </div>
                </Tab>
                <Tab eventKey="russia-stories" title="Russia Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"russia": true}}
                            selectedSortType="Last Updated"
                            russiaCluster={true}
                            trackerType={["russia news"]}
                            preset_query='United States Election'
                        />
                    </div>
                </Tab>
                <Tab eventKey="iran-stories" title="Iran Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"iran": true}}
                            selectedSortType="Last Updated"
                            iranCluster={true}
                            trackerType={["iran news"]}
                            preset_query='United States Election'
                        />
                    </div>
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors global coverage of the 2024 U.S. elections.
                        <br /><br />
                        <b><i>USA Narratives:</i></b> Coverage exclusively from U.S. news sources.
                        <br /><br />
                        <b><i>Global Narratives:</i></b> Coverage from top global news sources.
                        <br /><br />
                        <b><i>China Narratives:</i></b> Coverage exclusively from Chinese news sources.
                        <br /><br />
                        <b><i>Russia Narratives:</i></b> Coverage exclusively from Russian news sources.
                        <br /><br />
                        <b><i>Iran Narratives:</i></b> Coverage exclusively from Iranian news sources.
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default ElectionNarratives;
