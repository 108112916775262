import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../GlobalStyles.css'; // Import the modified CSS file
import GridLoader from 'react-spinners/GridLoader';
import { IoCheckmarkCircle } from "react-icons/io5";
import Alert from "react-bootstrap/Alert";


const Home = () => {

  const [loaderSize, setLoaderSize] = useState(0);
  const imageContainerRef = useRef(null);

  // Check if isMobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (imageContainerRef.current) {
      const containerWidth = imageContainerRef.current.offsetWidth;
      setLoaderSize(containerWidth * 0.17); // 75% of the container width
    }
  }, []);


  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', // One column on mobile, two columns otherwise
    textAlign: 'center',
    gap: '5px', // Space between columns
  };

  return (
    <div>
      <div>
        <a href="https://verbaai.org/globalpulse" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
            Check out Global Pulse for briefings on the top news from around the world!
          </Alert>
        </a>
      </div>
      <div className="home-container">
        <div className="content-flex-container">
          <div className="text-content">
            {!isMobile && (
              <div style={{ fontSize: "4.5rem", fontWeight: 500, lineHeight: 1 }}> Know what's happening,<br /> when it's happening.</div>
            )}
            {isMobile && (
              <div style={{ fontSize: "3rem", fontWeight: 500, lineHeight: 1 }}> Know what's happening,<br /> when it's happening.</div>
            )}
            <div style={{ height: "1rem" }}></div>
            <p>Monitor by-the-minute reporting from across the world, all in one place. Get critical insights and analysis in seconds.</p>

            {/* <p>We understand the challenge of keeping up with the fast-paced world of current events. That's why we built VerbaAI.</p> */}
            <div>
              <Link to="/international" className="btn-link">
                <button className="btn-custom btn-charcoal-gray mr-1 mb-2">Get Started</button>
              </Link>
              <Link to="/about" className="btn-link">
                <button className="btn-custom btn-mid-gray">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="image-content" ref={imageContainerRef}>
            <div className="loader-container hide-on-mobile">
              <GridLoader color="#000000" speedMultiplier={.1} size={loaderSize} />
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (<br />)}
      <div className="home-container" style={{ background: '#F8F9FA', borderRadius: '20px' }}>
        <div className="text-content" style={{ textAlign: 'center' }}>
          <div>
            <h1>Combatting 2024 Election Misinfo with the League of Women Voters <br/>Illinois, Utah, and Virginia</h1>
          </div>
          <p>The spread of false information during elections poses a critical threat to U.S. democracy.
            We've proudly partnered with the LWV-Illinois, LWV-Utah, and LWV-Virginia to launch Election Facts: a non-partisan, one-stop-shop for fact-checking U.S. election news.
          </p>
          <Link to="/election_facts" className="btn-link">
            <button className="btn-custom btn-charcoal-gray mr-1 mb-2">Try Election Facts</button>
          </Link>
        </div>
      </div>
      <br />
      <div style={{ height: "0.75rem" }} />
      <div className="base-container">
        <h1>What Our Users Say</h1>
      </div>
      <div className="home-container">
        <div className="plans-container">
          <div className="account-option mb-2">
            <h4>
              "VerbaAI is my go-to source for world news. It has the most comprehensive coverage out there."
            </h4>
            <h4><i>- Federal Consultant</i></h4>
          </div>
          <div className="account-option mb-2">
            <h4>
              "VerbaAI is a game-changer for monitoring rapidly developing situations."
            </h4>
            <h4><i>- Foreign Service Officer</i></h4>
          </div>
          <div className="account-option mb-2">
            <h4>
              "VerbaAI helps me understand perspectives from foreign countries, even if I don't speak the language."
            </h4>
            <br />
            <h4><i>- Fulbright Scholar</i></h4>
          </div>
        </div>
      </div>
      <div style={{ height: "0.75rem" }} />
      <div className="base-container">
        <h1>Backed By</h1>
      </div>
      <div style={{ height: "0.75rem" }} />
      <div style={gridContainerStyle}>
        <div>
          <Link to="https://www.virginiaipc.org/" className="btn-link">
            <img src="https://i.imgur.com/LaXN2E2_d.webp?maxwidth=1520&fidelity=grand" style={{ height: 'auto', maxWidth: '70%' }} />
          </Link>
        </div>
        <div>
          <Link to="https://www.lighthouselabsrva.org/" className="btn-link">
            <img src="https://i.imgur.com/qqxLmnR_d.webp?maxwidth=1520&fidelity=grand" style={{ height: 'auto', maxWidth: '70%' }} />
          </Link>
        </div>
      </div>
      {!isMobile && (
        <br />
      )}
    </div>
  );
}
export default Home;
