import React from 'react';
import '../GlobalStyles.css';


const About = () => {
  return (
    <div className="base-container">
      <div className="text-content">
        <h1 style={{ textAlign: 'center' }}>About VerbaAI</h1>
        <br />
        <h3>Our Mission: To develop software that strengthens our information environment.</h3>
        <p>We've spent years <a href="https://www.diplomaticourier.com/people/aaraj-vij" style={{ textDecoration: 'underline', color: 'inherit' }}> researching</a> our information environment as directors of <a href="https://www.disinfolab.wm.edu/" style={{ textDecoration: 'underline', color: 'inherit' }}>W&M DisinfoLab</a>. Now, we're building tools to strengthen it.
          We're backed by <a href="https://www.lighthouselabsrva.org/post/announcing-batch-17-of-lighthouse-labs" style={{ textDecoration: 'underline', color: 'inherit' }}> Lighthouse Labs RVA</a> and the <a href="https://www.virginiaipc.org/about-us/" style={{ textDecoration: 'underline', color: 'inherit' }}> Virginia IPC</a>.</p>
        <br />
        <h3>Our Vision: To make every person more informed online.</h3>
        <p>Everyone benefits from informed decision making. That's why we prioritize:</p>
        <ul>
          <li><span style={{ fontWeight: 500 }}>Cutting-Edge AI:</span> We leverage the latest breakthroughs in AI to give you the critical insights you need, when you need them.</li>
          <li><span style={{ fontWeight: 500 }}>User-Centric Design:</span> Our software is intuitive and easy to use out of the box, regardless of your technical background.</li>
          <li><span style={{ fontWeight: 500 }}>Customized Intelligence:</span> We build tools for a variety of professionals, and tailor them to your specific needs.</li>
        </ul>
        <br />
        <h3>Our Products</h3>
        <p>Whether you’re a policymaker crafting legislation, a defense analyst assessing risks, or an academic researching global trends, we've got you covered with VerbaAI and Forentify.</p>
        <br />
        <h3><a href="https://verbaai.org/" style={{ textDecoration: 'underline', color: 'inherit' }}> VerbaAI:</a>  Know what's happening, when it's happening.</h3>
        <ul>
          <li><span style={{ fontWeight: 500 }}>Real-Time News Tracking:</span> Stay updated with by-the-minute reporting from hundreds of outlets around the world, all in one place.</li>
          <li><span style={{ fontWeight: 500 }}>Narrative Tracking:</span> Watch narratives develop in real time. Be the first to know.</li>
          <li><span style={{ fontWeight: 500 }}>AI-Powered Summaries:</span> No more slogging through lengthy articles. Get key insights in a few bullet points.</li>
          <li><span style={{ fontWeight: 500 }}>Comprehensive Search:</span> Use natural language to query our exhaustive database for the most relevant results.</li>
          <li><span style={{ fontWeight: 500 }}>Multilingual Translation:</span> Don't miss out on critical international insights due to language barriers.</li>
          <li><span style={{ fontWeight: 500 }}>Custom Trackers:</span> Set specific trackers for issues or regions of interests, ensuring you never miss crucial developments.</li>
        </ul>
        <br />
        <h3><a href="https://forentify.com/" style={{ textDecoration: 'underline', color: 'inherit' }}> Forentify:</a>  Detect manipulated media, down to the pixel.</h3>
        <ul>
          <li><span style={{ fontWeight: 500 }}>Specific Manipulation Detection:</span> Forentify goes beyond a simple “real” or “fake” classification by identifying the precise manipulation present, whether it’s a deepfake, synthetic audio, or an altered background.</li>
          <li><span style={{ fontWeight: 500 }}>Fully Explainable Predictions:</span> Using white-box models, Forentify pinpoints manipulated content frame-by-frame and pixel-by-pixel, providing users with transparent and understandable results.</li>
          <li><span style={{ fontWeight: 500 }}>Image, Video, and Audio Support:</span> Forentify can detect manipulations across a variety of media types, ensuring comprehensive coverage.</li>
        </ul>
        <br />
        <h3>Contact Us</h3>
        <p>Reach out to learn how VerbaAI can help your organization revolutionize its analysis. Contact us at (info@verbaai.org).</p>
      </div>
    </div>
  );
};

export default About;
