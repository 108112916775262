import React from 'react';
import '../GlobalStyles.css'; // Import the CSS file
import { FaRegBookmark, FaBookmark, FaInfo, FaPlusCircle } from "react-icons/fa";


const UserGuide = () => {
  return (
    <div className="base-container">
      <div className="text-content">
        <h1 style={{ textAlign: 'center' }}>User Guide</h1>
        <h2>Search</h2>
        <h3>
          Explore our database using flexible search parameters
        </h3>
        <ul>
          <li>
            <span style={{fontWeight: 500}}>AI-Powered Search:</span> Use natural language to query our database for relevant results.
          </li>
          <li>
            <span style={{fontWeight: 500}}>Sources:</span> Find news from specific media outlets.
          </li>
          <li>
            <span style={{fontWeight: 500}}>Countries:</span> Find news from specific countries.
          </li>
          <li>
            <span style={{fontWeight: 500}}>Topics:</span> Find news about topics like "Conflict, War and Peace" or "Environment." We use <a href="https://iptc.org/standards/media-topics/" style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">IPTC</a> global standards.
          </li>
          <li>
            <span style={{fontWeight: 500}}>Dates:</span> Find news from a specified date range.
          </li>
        </ul>
        <br />
        <h3>Select multiple options in one search parameter</h3>
        <p>
          Select multiple options to broaden your search. Results will match at least one of your specified options. <br />
        </p>
        <br />
        <h3>Using different search parameters together</h3>
        <p>
          Combine different parameters to narrow your search. Results will match all specified conditions.
        </p>
        <hr />
        <br />
        <h2>My News (Requires Sign In):</h2>
        <h3>Bookmark articles for easy access</h3>
        <p>
          Click the bookmark icon to the right of any article  (<FaBookmark />). Bookmarked articles will appear under My News {'>'} Bookmarks.
        </p>
        <br />
        <h3>Create a custom topic tracker</h3>
        <p>
          Navigate to My News {'>'} Add Custom <FaPlusCircle />. Select search parameters for your custom tracker, and use the "Preview" button to refine them.
          Then, give your tracker a name and hit the "Create" button. Custom trackers will appear under My News {'>'} (Tracker Name).
        </p>
        <br />
      </div>
    </div>
  );
};

export default UserGuide;
