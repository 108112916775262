import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom'; // Make sure to import these
import SignIn from '../google/SignIn'; // Adjust the path as necessary

function NavBar() {
  const activeStyle = { fontWeight: 500 };  // Style for the active NavLink
  const [showDropdownInternational, setShowDropdownInternational] = useState(false); // State to manage dropdown visibility
  const [showDropdownIssueTrackers, setShowDropdownIssueTrackers] = useState(false); // State to manage dropdown visibility
  const [showDropdownElectionFacts, setShowDropdownElectionFacts] = useState(false); // State to manage dropdown visibility


  return (
    // changed navbar-expand-md to navbar-expand-lg
    <nav className="navbar navbar-expand-lg navbar-light bg-light" >

      <div className="container">
        <Link className="navbar-brand navbar-text-color" to="/">
          <img src="/site_logo_2.png" alt="VerbaAI Logo" height="40" />{" "}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/globalpulse" style={({ isActive }) => isActive ? activeStyle : undefined}>
                Global Pulse
              </NavLink>
            </li>
            <li className="nav-item"
              onMouseEnter={() => setShowDropdownInternational(true)}
              onMouseLeave={() => setShowDropdownInternational(false)}>
              <NavLink className="nav-link" to="/international" style={({ isActive }) => isActive ? activeStyle : undefined}>
                International
              </NavLink>
              <ul className={`dropdown-menu${showDropdownInternational ? ' show' : ''}`}>
                <li><NavLink className="dropdown-item navbar-link" to="/region/united_states">United States</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/region/mena">Middle East</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/region/pacific_rim">Pacific Rim</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/region/asia">Asia</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/region/europe">Europe</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/region/sub_saharan_africa">Africa</NavLink></li>
              </ul>
            </li>
            <li className="nav-item"
              onMouseEnter={() => setShowDropdownElectionFacts(true)}
              onMouseLeave={() => setShowDropdownElectionFacts(false)}>
              <NavLink className="nav-link" to="/election" style={({ isActive }) => isActive ? activeStyle : undefined}>
                U.S. Elections
              </NavLink>
              <ul className={`dropdown-menu${showDropdownElectionFacts ? ' show' : ''}`}>
                <li><NavLink className="dropdown-item navbar-link" to="/election_narratives">Election Narratives</NavLink></li>
                {/* <li><NavLink className="dropdown-item navbar-link" to="/election_visualizer">Election Visualizer</NavLink></li> */}
                <li><NavLink className="dropdown-item navbar-link" to="/election_facts">Election Facts</NavLink></li>
              </ul>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/financial" style={({ isActive }) => isActive ? activeStyle : undefined}>
                s
              </NavLink>
            </li> */}
            {/* Dropdown for Issue Trackers */}
            <li className="nav-item"
              onMouseEnter={() => setShowDropdownIssueTrackers(true)}
              onMouseLeave={() => setShowDropdownIssueTrackers(false)}>
              <NavLink className="nav-link" to="/issue-trackers" style={({ isActive }) => isActive ? activeStyle : undefined}>
                Issue Trackers
              </NavLink>
              <ul className={`dropdown-menu${showDropdownIssueTrackers ? ' show' : ''}`}>
                <li><NavLink className="dropdown-item navbar-link" to="/issue-tracker/israel_hamas_war">Israel-Hamas War</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/issue-tracker/us_presidential_election_2024">2024 U.S. Election</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/issue-tracker/south_china_sea">South China Sea</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/issue-tracker/russia_ukraine_war">Russia-Ukraine War</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/issue-tracker/climate_change">Climate Change</NavLink></li>
                <li><NavLink className="dropdown-item navbar-link" to="/mynews">Create Custom</NavLink></li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/mynews" style={({ isActive }) => isActive ? activeStyle : undefined}>
                My News
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/userguide" style={({ isActive }) => isActive ? activeStyle : undefined}>
                User Guide
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/about" style={({ isActive }) => isActive ? activeStyle : undefined}>
                About
              </NavLink>
            </li>
          </ul>
          <div className="ms-auto">
            <SignIn />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;