import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';

const customSourceOptions = [
    { label: 'FactCheck - factcheck.org', value: 'factcheck.org' },
    { label: 'Lead Stories - leadstories.com', value: 'leadstories.com' },
    { label: 'PolitiFact - politifact.com', value: 'politifact.com' },
    { label: 'Snopes - snopes.com', value: 'snopes.com' },
    { label: 'Reuters - reuters.com', value: 'reuters.com' },
    { label: 'USA Today - usatoday.com', value: 'usatoday.com' },
    { label: 'AP News - apnews.com', value: 'apnews.com' }
];

const ElectionFactsUSA = () => {
    const { user } = useUser();
    const filters = {
        "tracker_type": ["fact_check"],
        "topics": ["Politics"],
        "locations": ["United States"]
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Election Facts USA</h1>
            <h2 style={{ textAlign: 'center' }}>Curated from Leading Fact-Checking Organizations</h2>
            {!user && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
            )}
            <SearchableTable electionFactsTracker={true} baseRequest={filters} showCluster={false} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={customSourceOptions} excludePast24Hours={true} />
        </div>
    );
};

export default ElectionFactsUSA;
