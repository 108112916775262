import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getDatePublished, getTimePublished, getTimezoneName } from "../search/DataTableCluster";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useSwipeable } from 'react-swipeable';
import '../GlobalStyles.css';

function NarrativeHistory({ data }) {

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const titleHistory = data[0]?.title_history || [];
    const summaryHistory = data[0]?.summary_history || [];
    const [currentIndex, setCurrentIndex] = useState(titleHistory.length - 1);


    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < titleHistory.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div {...handlers}>
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <span>
                                <div>
                                    <button
                                        style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }}
                                        className="mr-1"
                                        onClick={handlePrevious}
                                        disabled={currentIndex === 0}>
                                        <GrPrevious />
                                    </button>
                                    <span style={{ fontWeight: 500 }}>{currentIndex + 1}/{titleHistory.length}</span>
                                    <button
                                        style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }}
                                        className="ml-1 mr-1"
                                        onClick={handleNext}
                                        disabled={currentIndex === titleHistory.length - 1}>
                                        <GrNext />
                                    </button>
                                    {isMobile && (
                                        <br />
                                    )}
                                    <span>
                                        <i>
                                            {" "}Summarized: {getDatePublished(titleHistory[currentIndex]?.timestamp.replace(" ", "T") + "+00:00")},
                                            {" "}{getTimePublished(titleHistory[currentIndex]?.timestamp.replace(" ", "T") + "+00:00")}
                                            {" "}({getTimezoneName(titleHistory[currentIndex]?.timestamp.replace(" ", "T") + "+00:00")})
                                        </i>
                                    </span>
                                </div>
                            </span>
                            <br />
                            <h4>{titleHistory[currentIndex]?.title}</h4>
                            <span style={{ whiteSpace: "pre-line" }}>
                                <div style={{ height: '0.75rem' }}></div>
                                {summaryHistory[currentIndex]?.summary.replace(/^-\. /gm, "\u2022 ").replace(/^- /gm, "\u2022 ").replace(/\*/g, '').replace(/^\s*$(?:\r\n?|\n)/gm, '')}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default NarrativeHistory;
